// font family
$font-family: apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

@mixin regularFont {
  font-family: 'Montserrat-Regular', 'PingFangSC-Regular', $font-family !important;
  // font-weight: 400 !important;
}

@mixin semiBoldFont {
  font-family: 'Montserrat-SemiBold', 'PingFangSC-Semibold', $font-family !important;
  // font-weight: 600 !important;
}

@mixin mediumFont {
  font-family: 'Montserrat-Medium', 'PingFangSC-Medium', $font-family !important;
  // font-weight: 500 !important;
}

@mixin lightFont {
  font-family: 'Montserrat-Light', 'PingFangSC-Light', $font-family !important;
  // font-weight: 400 !important;
}

@mixin boldFont {
  font-family: 'Montserrat-Bold', 'PingFangSC-Semibold', $font-family !important;
  // font-weight: 700 !important;
}

@mixin lightUbuntuFont {
  font-family: 'Ubuntu-Light', 'PingFangSC-Light', $font-family !important;
  // font-weight: 400 !important;
}

@mixin regularUbuntuFont {
  font-family: 'Ubuntu-Regular', 'PingFangSC-Regular', $font-family !important;
  // font-weight: 400 !important;
}

@mixin mediumUbuntuFont {
  font-family: 'Ubuntu-Medium', 'PingFangSC-Medium', $font-family !important;
  // font-weight: 500 !important;
}

@mixin boldUbuntuFont {
  font-family: 'Ubuntu-Bold', 'PingFangSC-Semibold', $font-family !important;
  // font-weight: 700 !important;
}

@mixin PoppinsFont {
  font-family: 'Poppins', 'PingFangSC-Regular', $font-family !important;
}

//  font size
@mixin fontSize($size, $lineHeight) {
  font-size: $size !important;
  line-height: $lineHeight !important;
}

@mixin fontSize-12 {
  @include fontSize(12px, 20px);
}

@mixin fontSize-14 {
  @include fontSize(14px, 22px);
}
@mixin fontSize-16 {
  @include fontSize(16px, 24px);
}
@mixin fontSize-18 {
  @include fontSize(18px, 26px);
}
@mixin fontSize-20 {
  @include fontSize(20px, 28px);
}
@mixin fontSize-24 {
  @include fontSize(24px, 32px);
}

@mixin fontSize-28 {
  @include fontSize(28px, 36px);
}

@mixin fontSize-30 {
  @include fontSize(30px, 38px);
}

@mixin fontSize-32 {
  @include fontSize(32px, 40px);
}

@mixin fontSize-48 {
  @include fontSize(48px, 56px);
}

@mixin fontSize-52 {
  @include fontSize(52px, 60px);
}
