.tooltipSeriesLine {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.85);
  line-height: 20px;
  margin-top: 8px;
}

.tooltipTitle {
  text-align: center;
  font-size: 12px;
  font-family: Montserrat-Medium, Montserrat;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.85);
  line-height: 20px;
}

.tooltipSeriesMarker {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 8px;
}
