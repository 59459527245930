@import '../../../../common/colors-variable.scss';
@import '../../../../common/fonts.scss';

.registerInvalidContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 140px;
  font-family: bold;
  font-size: 24px;
  color: #202124;
  text-align: center;
  line-height: 32px;
}

.registerRedirectContainer {
  width: 100%;
  margin-top: 24px;
  height: 18px;
  line-height: 18px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.registerRedirectContainer > * {
  font-size: 14px;
  height: 100%;
  width: fit-content;
}

.registerRedirectContainer > a {
  margin-left: 10px;
}
