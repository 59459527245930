@value colors: '../../common/color.module.css';
@value fontShark, fontWhite, fontSunsetOrange, backgroundSunsetOrange, backgroundWhite from colors;

.deprecatedTimer {
  margin-top: 30px;
  color: #fff;
  font-size: 14px;
}

.navButton {
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 12px 0 12px;
  border-radius: 4px;
}

.navButton[marker='hover'] {
  background: #f6f6f6;
}

.navButton[marker='highlight'] {
  background: #3e3ef4;
  color: #fff;
}

.navButtonImg {
  display: block;
  width: 24px;
  height: 24px;
  margin-left: 12px;
}

.navButtonLabel {
  font-size: 14px;
  margin-left: 12px;
  line-height: 22px;
  text-align: left;
  flex: 1;
  font-weight: 500;
}
.navButtonHasNewBadge {
  width: 44px;
  height: 20px;
  color: fontWhite;
  background: backgroundSunsetOrange;
  border-radius: 10px;
  margin-right: 18px;
  text-align: center;
  line-height: 20px;
}
.navButtonHasNewBadge[marker='hover'],
.navButtonHasNewBadge[marker='highlight'] {
  color: fontSunsetOrange;
  background: backgroundWhite;
}

.meLabel {
  height: 38px;
  line-height: 38px;
  color: #202124;
  font-size: 16px;
}

.meLabel[marker='light'] {
  height: 38px;
  line-height: 38px;
  color: #8e8f91;
  font-size: 16px;
}

.meLabel input {
  border: none;
  font-size: 16px;
  height: 100%;
  padding: 0;
  line-height: 18px;
  color: #202124;
  border-radius: 0;
  padding: 0 0 10px 0;
}

.meLabel input:hover {
  border-bottom: 1px solid #ededed;
}

.meLabel input:focus {
  border: none !important;
  border-bottom: 1px solid #ededed !important;
}

.modalLabel {
  height: 18px;
  line-height: 18px;
  font-size: 14px;
}

.modalButton {
  width: 400px;
}

.modalLabel {
  height: 18px;
  line-height: 18px;
  font-size: 14px;
}

.meAccountBindModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px 20px 20px;
}

.meAccountBindQR {
  width: 280px;
  height: 280px;
}

.meAccountBindNotice {
  margin-top: 10px;
  line-height: 22px;
  font-size: 14px;
  color: #f63f45;
}

.meAccountBindTimer {
  margin-top: 10px;
  line-height: 22px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  color: #f63f45;
}

.meAccountBindTimer > * {
  margin: 0 2px;
}

.meAccountBindAuthModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px 20px 20px;
}
.meAccountBindAuthNotice {
  margin-top: 20px;
  line-height: 22px;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.meAccountBindAuthNoticeLink {
  margin: 0px 10px;
}
.meAccountBindAuthQR {
  height: 300px;
}

.footer {
  display: none;
}

.homeContainer {
  display: flex;
  /* flex-direction: column; */
  margin: 0;
  /* width: 100%; */
  height: 100%;
}

.homeContent {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex: 1;
  background: #fbfbfd;
}

.contentContainer {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: flex-start;
  overflow: auto;
  height: 100%;
  color: fontShark;
}

.content {
  flex: 1;
  box-sizing: border-box;
  padding: 30px;
  height: 100%;
}

.superLoopContent {
  composes: content;
  padding: 0;
  background: #edf2f9;
}

.meContent {
  composes: content;
  padding: 0;
  background: #edf2f9;
}

.meAccountPanel {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  width: fit-content;
}

.meAccountPanel > div {
  display: flex;
  flex-direction: row;
}

.meAccountPanel > div:nth-child(1) > div {
  display: flex;
  flex-direction: column;
}

.meAccountPanel > div:nth-child(1) > div:nth-child(1) > div {
  display: flex;
  flex-direction: row;
  width: 890px;
  height: 80px;
  border-bottom: 1px solid #ededed;
  border-right: 1px solid #ededed;
  align-items: center;
}

.meAccountPanel > div:nth-child(1) > div:nth-child(2) {
  width: 220px;
}

.meAccountPanel > div:nth-child(1) > div:nth-child(1) > div > div:nth-child(1) {
  width: 100px;
  margin-left: 30px;
}

.meAccountPanel > div:nth-child(1) > div:nth-child(1) > div > div:nth-child(2) {
  width: 300px;
  margin-left: 155px;
}

.meAccountPanel > div:nth-child(1) > div:nth-child(1) > div > div:nth-child(3) {
  margin-left: 80px;
}

.meAccountPanel > div:nth-child(2) {
  display: flex;
  width: 890px;
  height: 100px;
  align-items: center;
}

.meAccountPanel > div:nth-child(2) > div:nth-child(1) {
  flex-direction: column;
  margin-left: 30px;
}

.meAccountPanel > div:nth-child(2) > div:nth-child(2) {
  flex: 1;
  margin-left: 40px;
}

.meAccountPasswordModal {
  /*padding: 0 20px 20px 20px;*/
}

.meAccountPasswordModalLine {
  margin: 0 0 24px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.meAccountPasswordModalLine input {
  margin-top: 10px;
}

.meAccountAvatar {
  width: 140px;
  height: 140px;
  line-height: 140px;
  margin: 30px 0 0 40px;
}

.meAccountAvatar > div {
  font-size: 70px;
}

.mePreferencePanel {
  display: flex;
  flex-direction: column;
  /* margin-top: 24px; */
}

.mePreferencePanel > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 106px;
}

.mePreferencePanel > div:nth-child(n + 2) {
  border-top: 1px solid #ededed;
}

.mePreferencePanel > div > div:nth-child(1) {
  width: 120px;
  margin-left: 30px;
}

.mePreferencePanel > div > div:nth-child(2) {
  width: 340px;
  margin-left: 30px;
}

.teamContent {
  composes: content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  min-height: 1000px;
  padding: 30px 0;
}

.teamContainer {
  width: 100%;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.teamInvite {
  font-size: 14px;
}

.teamCells > div:nth-child(1) {
  margin: 0 25px;
  flex: 40;
  text-align: center;
}

.teamCells > div:nth-child(2) {
  margin-left: 15px;
  flex: 185;
  align-items: center;
  word-break: break-all;
}

.teamCells > div:nth-child(3) {
  flex: 150;
  word-break: break-all;
  margin: 0 20px;
}

.teamCells > div:nth-child(4) {
  flex: 130;
}

.teamCells > div:nth-child(5) {
  flex: 140;
}

.teamCells > div:nth-child(6) {
  flex: 200;
}
.teamCells > div:nth-child(7) {
  flex: 440;
}

.teamTitle {
  composes: teamCells;
}

.teamLine {
  composes: teamCells;
  align-items: center;
}

.teamLine > div:nth-child(2) {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.teamLine > div:nth-child(2) > div:nth-child(1) {
  width: 40px;
}

.teamLine > div:nth-child(2) > div:nth-child(2) {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.teamLineIndex {
  text-align: center;
  font-size: 16px;
}

.teamLineAvatar {
  width: 40px;
  height: 40px;
  line-height: 40px;
}

.teamLineAvatar > div {
  font-size: 20px;
}

.teamLineName {
  font-size: 16px;
  color: #3e3ef4;
}

.teamLineStatus {
  border-radius: 11px;
  padding: 2px 11px;
  font-size: 12px;
  color: #fff;
  width: fit-content;
}

.teamLineStatus[marker='active'] {
  background: #11bf5a;
}

.teamLineStatus[marker='pending'] {
  background: #f2752e;
}

.teamLineStatus[marker='frozen'] {
  background: #666;
}

.teamLineRole {
  font-size: 16px;
  color: #3e3ef4;
}

.teamLineWallet {
  font-size: 16px;
  color: #3e3ef4;
}

.teamLineActions {
  display: flex;
  flex-direction: row;
}

.teamLineAction {
  margin-right: 30px;
  font-size: 16px;
  color: #f63f45;
}

.teamLineAction[marker='resend'] {
  color: #3e3ef4;
}

.teamUserModal {
  width: 560px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.teamUserModalLine {
  margin: 0 0 24px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 460px;
}

.teamUserModalLine > div:nth-child(1) {
  margin-bottom: 10px;
}

.teamUserModalWarning {
  font-size: 14px;
  line-height: 24px;
}

.teamUserModalButtons {
  display: flex;
  flex-direction: row;
  width: 460px;
  justify-content: center;
  margin-bottom: 10px;
}

.teamUserModalButtons > div {
  flex: 1;
  max-width: 400px;
  margin-top: 30px;
  font-size: 16px;
}

.teamUserModalButtons > div:nth-child(n + 2) {
  margin-left: 20px;
}

.teamUserModalPhoneEdit {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.teamUserModalPhoneEdit > div:nth-child(2) {
  width: 77%;
}

.teamUserModalPhoneCountry {
  width: 20%;
}

.teamUserModalPhoneCountry > div:nth-child(2) {
  top: unset;
  bottom: 120%;
  width: 500%;
  max-height: 250px;
}

.teamUserRoleModalLine {
  margin: 0 0 20px 0;
  display: flex;
  flex-direction: column;
}

.teamUserRoleModalLine > div {
  height: 18px;
  width: 100%;
  margin-bottom: 12px;
}

.teamUserRoleModalLine > div:nth-child(1) {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.teamUserRoleModalLineLabel {
  height: 18px;
  line-height: 18px;
  font-size: 14px;
  font-weight: bold;
  color: #202124;
}

.teamUserRoleModalButton {
  margin-bottom: 20px;
  font-size: 16px;
  margin-top: 30px;
}

.teamUserRoleModalLineDescription {
  height: 18px;
  line-height: 18px;
  width: 100%;
  font-size: 14px;
  color: #b5b5b6;
}

.teamUserWalletModalLines {
  width: 400px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.teamUserWalletModalLine {
  display: flex;
  flex-direction: row;
  width: 50%;
}

.teamUserWalletModalLineCheck {
  width: 18px;
  height: 18px;
}

.teamUserWalletModalLabel {
  line-height: 18px;
  height: 18px;
  margin-left: 15px;
  margin-bottom: 24px;
  font-size: 14px;
  font-weight: bold;
}

.txContent {
  composes: content;
  padding: 0;
}

.otcContent {
  composes: content;
}

.posContent {
  composes: content;
}

.billContent {
  composes: content;
  background: #edf2f9;
}

.walletContent {
  composes: content;
  padding: 0;
}

.web3WalletContent {
  composes: content;
  padding: 0;
  background: #edf2f9;
}

.lineMessager {
  position: absolute;
  top: 64px;
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 101;
}

.lineMessagerFading {
  composes: lineMessager;
  top: 20px;
}

.lineMessagerFading[marker='success'] {
  transition: top 0.4s 1s;
}

.lineMessagerFading[marker='error'] {
  transition: top 0.4s 1s;
}

.lineMessager[marker='success'] {
  background: #11bf5a;
}

.lineMessager[marker='error'] {
  background: #f53e44;
}

.lineMessagerContent {
  height: 20px;
  line-height: 20px;
  display: flex;
  flex-direction: row;
}

.lineMessagerContentImg {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.lineMessagerText {
  font-weight: 500;
  font-size: 14px;
  color: #fff;
}

.paste {
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  color: #3e3ef4;
  line-height: 20px;
}

.pasteContainer {
  width: 400px;
  padding-top: 5px;
  text-align: right;
}

.obviousText {
  color: #1890ff;
  white-space: pre;
}

.flexible {
  flex: 1;
}

.legendTradingContainer {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #edf2f9;
}

.legendTradingContent {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.legendTradingHeader {
  height: auto;
  background: #fff;
  padding: 24px;
}

.legendTradingHeaderTitle {
  font-family: 'Montserrat-SemiBold', 'PingFangSC-Semibold';
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  margin: 0 0 12px 0;
}

.legendTradingHeaderDesc {
  font-family: 'Montserrat-Regular', 'PingFangSC-Regular';
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.legendTradingHeaderDesc strong {
  font-family: 'Montserrat-Medium', 'PingFangSC-Medium';
}

.legendIframeContainer {
  flex: 1;
}

.legendIframeContainer > iframe {
  width: 100%;
  height: 100%;
}

.legendIframeContent {
  /* font-family: 'Montserrat-SemiBold', 'PingFangSC-Semibold'; */
  font-family: 'Montserrat-Medium', 'PingFangSC-Medium';
  font-weight: 600;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.oneKeyKycContainer {
  margin: 24px;
  padding: 24px;
  background: #ffffff;
  box-shadow: 0px 6px 18px -8px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
}

.oneKeyKycTitle {
  font-size: 20px;
  font-family: Montserrat-SemiBold, Montserrat;
  font-weight: 600;
  color: #181c27;
  line-height: 28px;
}

.oneKeyKycContent {
  padding: 24px;
  background: #f3f7fb;
  border-radius: 4px;
  margin: 24px 0;
}

.oneKeyKycItem {
  display: flex;
  align-items: center;
  margin: 0 0 40px 0;
}

.oneKeyKycItem:last-child {
  margin: 0;
}

.oneKeyKycItemImg {
  width: 80px;
  height: 80px;
  flex-shrink: 0;
}

.oneKeyKycItemWrapper {
  flex: 1;
  margin: 0 0 0 16px;
}

.oneKeyKycItemTitle {
  font-size: 16px;
  font-family: Montserrat-SemiBold, Montserrat;
  font-weight: 600;
  color: #181c27;
  line-height: 24px;
}

.oneKeyKycItemDescription {
  font-size: 14px;
  font-family: Montserrat-Medium, Montserrat;
  font-weight: 500;
  color: #777e90;
  line-height: 20px;
  margin: 8px 0 0 0;
}

.oneKeyKycButtonGroup {
  display: grid;
  grid-template-columns: minmax(137px, max-content) minmax(137px, max-content);
  column-gap: 24px;
}

.oneKeyKycFooter {
  font-size: 14px;
  font-family: Montserrat-Medium, Montserrat;
  font-weight: 500;
  color: #777e90;
  line-height: 20px;
  margin: 24px 0 0 0;
}

.kycInfoListContainer {
  margin: 20px 0 0 0;
}

.kycInfoListWrapper {
  padding: 0 0 64px 0;
}

.kycInfoListWrapper li {
  list-style: none;
}
