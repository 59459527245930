// colors variables
$royal-blue: #3e3ef4;
$governor-bay: #372dc8;
$oxford-blue: #353c4f;
$torch-red: #f5222d;
$chablis: #fff1f0;
$lima: #52c41a;
$aqua-spring: #f4f9fc;
$sugar-cane: #f6ffed;
$west-side: #fa8c16;
$early-dawn: #fff7e6;
$cornflower-blue: #6758f3;
$bay-of-many: #223887;
$hawkes-blue: #cfcffc;
$saffron-mango: #facf55;
$orange: #ff6d26;

$shark: #1b1d21;
$mirage: #181c27;
$white: #fff;
$white-lilac-light: #f9fafd;
$white-lilac-dark: #eae9f8;
$black: #000;
$tuna: #3e3f42;
$titan-white: #f0f2ff;
$athens-gray: #f2f3f7;
$link-water: #f3f3fc;
$mid-gray: #696a6d;
$shuttle-gray: #696b72;
$gray-chateau: #a6a7a8;
$jumbo: #808288;
$manatee: #959699;
$iron: #e3e3e5;
$black-haze: #eaebec;
$athens-gray: #f6f6f7;
$black-squeeze: #f9fbfd;
$black-squeeze-deep: #edf2f9;
$solitude: #e9f5ff;
$mystic: #eaedf3;
$gallery: #ededed;
$oslo-gray: #8e8f91;

// use color
$primary-color: $royal-blue;
$error-color: $torch-red;
$success-color: $lima;
$prompt-color: $west-side;
$propose-color: $cornflower-blue;
$bubble-color: $saffron-mango;
$primary-hover-color: $governor-bay;

$title-color: $shark;
$article-color: $mid-gray;
$caption-color: $manatee;
$border-color: $iron;
$split-line-color: $black-haze;
$disabled-color: $athens-gray;
$background-color: $black-squeeze;

@function mirageOpacity($opacity) {
  @return rgba($mirage, $opacity);
}

@function whiteOpacity($opacity) {
  @return rgba($white, $opacity);
}

@function blackOpacity($opacity) {
  @return rgba($black, $opacity);
}

@function royalBlueOpacity($opacity) {
  @return rgba($royal-blue, $opacity);
}
