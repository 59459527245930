@value colors: '../../../common/color.module.css';
@value fontBombay, fontRoyalBlue from colors;

.emphasisText {
  color: #3e3ef4;
}

.flexRowCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.listContainer {
  display: flex;
  flex-direction: column;
  padding: 30px;
}

.listContainer > div {
  width: 100%;
}

.listContainer > div:nth-child(1) {
  display: flex;
  align-items: center;
}

.listContainer > div:nth-child(2) {
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  margin-bottom: 36px;
  height: 50px;
  justify-content: space-between;
}

.listSearch {
  width: 700px;
}

.listCreate {
  font-size: 14px;
}

.listTitle {
  line-height: 32px;
  height: 32px;
  font-weight: bold;
  font-size: 24px;
  color: #202124;
}

.listEmpty {
  margin-top: 80px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.listEmpty > :nth-child(1) {
  height: 123px;
}

.listEmpty > :nth-child(2) {
  margin-top: 48px;
  font-size: 16px;
  color: #202124;
  text-align: center;
}

.listLine {
  margin-bottom: 20px;
  padding: 0 30px;
  height: 84px;
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.listLine:hover {
  background: #f6f6f6;
}

.listLineName {
  line-height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.listLineNameLabel {
  font-weight: 500;
  font-size: 18px;
  color: #202124;
  max-width: 500px;
  word-break: break-all;
}

.listLineCap {
  position: absolute;
  height: 100%;
  right: 100%;
  top: 0;
  width: 6px;
  border-radius: 6px 0px 0px 6px;
}

.listLine:nth-child(8n-5) .listLineCap {
  background: #ff6d26;
}

.listLine:nth-child(8n-4) .listLineCap {
  background: #3d5cf5;
}

.listLine:nth-child(8n-3) .listLineCap {
  background: #1fcf88;
}

.listLine:nth-child(8n-2) .listLineCap {
  background: #ffd543;
}

.listLine:nth-child(8n-1) .listLineCap {
  background: #f63f45;
}

.listLine:nth-child(8n) .listLineCap {
  background: #5e06c0;
}

.listLine:nth-child(8n + 1) .listLineCap {
  background: #da00d8;
}

.listLine:nth-child(8n + 2) .listLineCap {
  background: #8c8ca8;
}

.listLineNameArrow {
  height: 6px;
  width: 10px;
  margin-left: 11px;
}

.listLineBalance {
  margin-left: 11px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.listLineBalance > :nth-child(2) {
  width: 24px;
  height: 24px;
  margin-left: 30px;
}

.walletContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px;
}

.walletContainer > div:nth-child(n + 2) {
  width: 100%;
}

.walletContainer > div:nth-child(2) {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  justify-content: space-between;
  margin-top: 30px;
}

.walletContainer > div:nth-child(3) {
  margin-top: 24px;
}

.walletTitleWrapper {
  line-height: 32px;
  height: 32px;
  font-weight: bold;
  font-size: 24px;
  color: #202124;
  display: flex;
  flex-direction: row;
  align-items: center;

  margin: 0 0 16px 0;
}

.walletTitleFrozen {
  background: #666;
  font-size: 14px;
  color: #fff;
  border-radius: 3px;
  height: 26px;
  line-height: 26px;
  margin-left: 16px;
  padding: 0 15px;
}

.createModal {
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.createModalInput {
  margin: 50px 0;
}

.createModalButtons {
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 16px;
  width: 420px;
}

.createModalButtons > div {
  width: 200px;
}

.walletCoinsSelectModal {
}

.walletCoinsSelectModalSearch {
  margin: 0 40px 20px 0;
  width: 480px;
  height: 48px;
}

.walletCoinsSelectModalSearch input {
  box-shadow: unset;
  border: 1px solid #ededed;
}

.walletCoinsSelectModalCoins {
  width: 480px;
  height: 510px;
  margin-bottom: 20px;
  padding-right: 50px;
}

.walletCoinsSelectModalCoin {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 480px;
}

.walletCoinsSelectModalEmpty {
  width: 480px;
  margin: 20px 40px 0px 40px;
  color: #9ea0a5;
  font-size: 16px;
  text-align: center;
}

.walletCoinsSelectModalCoinLine {
  width: 480px;
  height: 1px;
  background: #ededed;
}

.walletCoinsSelectModalCoin > :nth-child(1) {
  display: flex;
  align-items: center;
}

.walletCoinsSelectModalCoin > :nth-child(1) > :nth-child(1) {
  width: 38px;
  height: 38px;
  z-index: 1;
}

.walletCoinsSelectModalCoin > :nth-child(1) > :nth-child(2) {
  font-weight: 500;
  font-size: 16px;
  color: #000;
  line-height: 20px;
  margin: 20px 0 20px 12px;
  max-width: 280px;
  display: flex;
  flex-direction: column;
}

.walletCoinsSelectModalCoinArrow {
  height: 38px;
  width: 42px;
  padding: 16px;
}

.walletCoinsSelectModalCoin > :nth-child(2) {
  height: 32px;
  min-height: 32px;
  line-height: 30px;
  padding: 0px;
  width: 74px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 12px;
  border-width: 1px;
}

.walletCoinsSelectModalSubCoin {
  composes: walletCoinsSelectModalCoin;
  margin-left: 50px;
  width: 430px;
  position: relative;
  border-top: 1px solid #ededed;
}

.walletCoinsSelectModalSubCoinAddress {
  height: 16px;
  font-size: 10px;
  font-weight: 400;
  color: #b5b5b6;
  line-height: 16px;
  margin-top: 3px;
  word-break: break-all;
}

.walletCoinsSelectModalSubCoinLineH {
  position: absolute;
  left: -30px;
  top: 50%;
  height: 1px;
  width: 20px;
  background: #ededed;
}

.walletCoinsSelectModalSubCoinLineV {
  position: absolute;
  left: -30px;
  top: -50%;
  width: 1px;
  height: 100%;
  background: #ededed;
}

.walletCoinsContainer {
  display: flex;
  flex-direction: column;
  margin: 0 0 200px 0;
  box-shadow: 0px 6px 18px -8px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  overflow: hidden;
}

.walletCoinsContainer > div {
  width: 100%;
}

/* .walletCoinsContainer > div:nth-child(1) {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: space-between;
} */

.walletCoinsEmpty {
  margin-top: 80px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.walletCoinsEmpty > :nth-child(1) {
  height: 123px;
}

.walletCoinsEmpty > :nth-child(2) {
  margin-top: 48px;
  font-size: 16px;
  color: #202124;
  text-align: center;
}

.walletCoinsSearch {
  width: 700px;
}

.walletCoinsLine {
  display: flex;
  flex-direction: row;
  height: 100px;
  align-items: center;
  border-bottom: 1px solid #ededed;
}

.walletCoinsLine > div:nth-child(1) {
  margin-left: 25px;
  flex: 5;
  position: relative;
}

/* .walletCoinsAmount {
  margin-left: 30px;
  flex: 2;
  margin-right: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
} */
/* .walletCoinsButton {
  flex: 4;
  display: flex;
  justify-content: flex-end;
}
.walletCoinsButton > div:nth-child(1) {
  margin-left: 10rem;
  margin-right: 30px;
}
.walletCoinsButton > div:nth-child(2) {
  margin-left: 20px;
  margin-right: 30px;
} */
.walletCoinsGo {
  width: 18px;
  height: 18px;
  /* margin-left: 30px; */
}
.walletCoinsGoImg {
  max-width: none;
}

.walletCoinsLineName {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  position: relative;
}

.walletCoinsLineCoinLabel {
  padding: 0 0 0 44px;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
}

.walletCoinsLineNameContent {
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* .walletCoinsLineNameLabel {
  font-weight: 500;
  font-size: 16px;
  color: #000;
} */

.walletCoinsLineNameHelp {
  position: relative;
  width: 16px;
  height: 16px;
  margin-left: 8px;
}

.walletCoinsLineNameHelpTitle {
  font-weight: bold;
}

.walletCoinsLineNameTooltipOverlay {
  width: 250px;
}

.walletCoinsLineNameTooltip > div {
  white-space: nowrap;
}

/* .walletCoinsLineTokenAddressLabel {
  height: 16px;
  font-size: 10px;
  font-weight: 400;
  color: #b5b5b6;
  line-height: 16px;
  margin-top: 3px;
} */

.walletCoinsIcon {
  width: 32px;
  height: 32px;
  position: absolute;
  top: 0;
  left: 0;
}

.walletCoinsAction {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  line-height: 20px;
}

.walletCoinsActionLabel {
  font-size: 16px;
  color: #3e3ef4;
}

.walletCoinsActionIcon {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

.walletCoinsSendModal {
  padding: 0 20px 0 20px;
}
.walletCoinsSendModalContainer {
  padding-bottom: 20px;
}
.walletCoinsSendModalLine {
  position: relative;
  margin: 12px 20px 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 520px;
  font-size: 14px;
  font-weight: 500;
  /* border-bottom: 1px solid #ededed; */
}

.addressIcon {
  width: 25px;
  margin: 0 0 20px 0;
  cursor: default;
}

.addressInfoContainer {
  width: 100%;
  position: absolute;
  left: 0;
  top: 48px;
  background: #fff;
  z-index: 999;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  box-sizing: border-box;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.12);
  max-height: 360px;
  overflow-y: auto;
}

.addressRemarkContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.addressRemark {
  font-size: 12px;
  font-family: Montserrat-Medium, Montserrat;
  font-weight: 500;
  color: #a6a7a8;
  line-height: 20px;
}

.whiteListTag {
  background: #0bc2f5;
  border-radius: 4px;
  padding: 0 8px;
  font-size: 12px;
  font-family:
    PingFangSC-Semibold,
    PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 20px;
}

.addressInfoOption {
  border-bottom: 1px solid #ededed;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 15px;
  height: auto;
  line-height: normal;
  cursor: pointer;
}

.addressInfoOptionLink {
  composes: addressInfoOption;
  color: #3e3ef4;
}

.walletCoinsSendModalLabelLine {
  justify-content: space-between;
  display: flex;
  align-items: center;
  font-size: 14px;
  margin: 30px 20px 0 20px;
}

.walletCoinsSendModalGasLine {
  justify-content: space-between;
  display: flex;
  align-items: start;
  font-size: 12px;
  color: #f48d2c;
  margin: 0 20px 0 20px;
}
.walletCoinsSendModalGasLineError {
  color: #f42c2c;
}

.walletCoinsSendModalInputWrapper {
  border-bottom: 1px solid #ededed;
}

.walletCoinsSendModalInputError {
  position: absolute;
  left: 0;
  bottom: 0;
}

.walletCoinsSendModalInput {
  padding: 0 0 24px 0;
}

.walletCoinsSendModalInput input {
  border: none;
  padding: 14px 0px;
  outline: none;
}

.walletCoinsSendModalInput input[marker='error'] {
  border: none;
}

.walletCoinsSendModalInput input:focus {
  border: none !important;
  outline: none !important;
}

.walletCoinsSendModalAmount {
  composes: walletCoinsSendModalInput;
  width: 30%;
}

.walletCoinsSendModalSubLine {
  margin: 12px 20px 0 20px;
  justify-content: flex-end;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.walletCoinsSendModalSubLabel {
  opacity: 0.3;
  color: #202124;
  text-align: center;
}

.walletCoinsSendModalSubButton {
  color: #3e3ef4;
  margin-left: 5px;
}
.walletCoinsSendModalButton {
  width: 400px;
}

.walletCoinsReceiveModal {
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.walletCoinsReceiveModalQR {
  position: relative;
  margin-top: -40px;
  width: 240px;
  height: 240px;
}

.walletCoinsReceiveModalQR img {
  width: 100%;
  height: 100%;
}

.walletCoinsReceiveModalQR > :nth-child(1) {
  position: absolute;
  width: 100%;
  height: 100%;
}

.walletCoinsReceiveModalQR > :nth-child(2) {
  position: absolute;
  top: 39%;
  left: 39%;
  width: 22%;
  height: 22%;
}

.walletCoinsReceiveModalAddress {
  width: 480px;
  height: 62px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  padding: 0 20px;
  color: #202124;
  border: 1px dashed #3e3ef4;
  margin: 30px 40px 6px 40px;
  background: #f6f6f6;
  word-break: break-all;
}

.walletCoinsReceiveModalMemo {
  width: 480px;
  height: 40px;
  font-weight: 500;
  font-size: 16px;
  line-height: 40px;
  padding: 0 20px;
  color: #202124;
  border: none;
  margin: 0px;
}

.walletCoinsReceiveModalWarning {
  font-size: 14px;
  line-height: 24px;
  width: 480px;
}

.walletCoinsReceiveModalButton {
  margin-top: 40px;
  width: 400px;
  height: 48px;
  font-size: 18px;
}

.walletCoinsReceiveModalTip {
  opacity: 0.3;
  font-size: 14px;
  color: #202124;
  margin-top: 20px;
}

.walletTxsContent {
  /* margin-top: 20px; */
}

.walletPolicyContent {
  padding: 0 0 112px 0;
}

.walletPolicyAllTitleGroup .walletPolicyAllTitle {
  display: inline-block;
}
.walletPolicyAllTitleGroup .walletPolicyAllSubTitle {
  display: inline-block;
  margin-left: 0;
}

.walletPolicyAllTitle {
  /* margin-top: 30px; */
  font-weight: bold;
  font-size: 18px;
  color: #202124;
  line-height: 22px;
}

.walletPolicyAllSubTitleWrapper {
  margin-top: 15px;
  display: flex;
  align-items: center;
}

.walletPolicyCheckbox {
  height: 18px;
  width: 18px;
}

.walletPolicyAllSubTitle {
  line-height: 22px;
  font-size: 14px;
  color: #b5b5b6;
  margin-left: 15px;
}

.walletPolicyTitle {
  margin-top: 40px;
  margin-bottom: 20px;
  height: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.walletPolicyTitle > div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.walletPolicyTitle > div:nth-child(1) > :nth-child(1) {
  font-weight: bold;
  line-height: 22px;
  font-size: 18px;
  color: #202124;
}

.walletPolicyTitle > div:nth-child(1) > :nth-child(2) {
  line-height: 22px;
  font-size: 14px;
  color: #b5b5b6;
  margin-left: 15px;
}

.walletPolicyTitle > div:nth-child(2) > :nth-child(1) {
  line-height: 14px;
  font-size: 12px;
  color: #b5b5b6;
}

.walletPolicyTitle > div:nth-child(2) > :nth-child(2) {
  line-height: 14px;
  font-size: 12px;
  margin-left: 5px;
}

.walletPolicySpeedAddCell > div:nth-child(1) {
  flex: 150;
  position: relative;
  max-width: 14%;
}

.walletPolicySpeedAddCell > div:nth-child(1) > :nth-child(1) {
  width: 100%;
  height: 46px;
  font-size: 14px;
  color: #b5b5b6;
  border: 1px solid #ededed;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 14px 16px 14px 16px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.walletPolicySpeedAddCell > div:nth-child(1) > :nth-child(1)[marker='value'] {
  color: #000;
}

.walletPolicySpeedAddCell > div:nth-child(1) > :nth-child(1):hover {
  color: #3e3ef4;
  background: #ebebff;
  border: 1px solid #3e3ef4;
  opacity: 1;
}

.walletPolicySpeedAddCell > div:nth-child(1) > :nth-child(1) > :nth-child(1) {
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.walletPolicySpeedAddCell > div:nth-child(1) > :nth-child(1) > :nth-child(2) {
  width: 10px;
  height: 10px;
}

.walletPolicySpeedAddCell > div:nth-child(1) > :nth-child(2) {
  left: 0;
  top: 120%;
}

.walletPolicySpeedAddCell > div:nth-child(1) > :nth-child(3) {
  margin-top: 4px;
  font-size: 12px;
  font-weight: bold;
  line-height: 12px;
  color: #f63f45;
}

.walletPolicySpeedAddCell > div:nth-child(2) {
  margin-left: 10px;
  flex: 200;
}

.walletPolicySpeedAddCell > div:nth-child(3) {
  margin-left: 10px;
  flex: 200;
}

.walletPolicySpeedAddCell > div:nth-child(4) {
  margin-left: 10px;
  flex: 200;
}

.walletPolicySpeedAddCell > div:nth-child(5) {
  margin-left: 10px;
  flex: 200;
}

.walletPolicySpeedAddCell > div:nth-child(6) {
  flex: 120;
  height: 48px;
  width: 48px;
}

.walletPolicySpeedAddTitle {
  composes: walletPolicySpeedAddCell;
  padding-top: 10px;
}

.walletPolicySpeedAddTitle > div {
  font-size: 14px;
  color: #202124;
}

.walletPolicySpeedAddLine {
  composes: walletPolicySpeedAddCell;
  border: none;
  padding: 10px 0 20px 0;
}

.walletPolicySpeedCell > div:nth-child(1) {
  width: 15%;
  overflow-wrap: break-word;
  margin-left: 25px;
}

.walletPolicySpeedCell > div:nth-child(2) {
  width: 16.333%;
  overflow-wrap: break-word;
}

.walletPolicySpeedCell > div:nth-child(3) {
  width: 16.333%;
  overflow-wrap: break-word;
}

.walletPolicySpeedCell > div:nth-child(4) {
  width: 16.333%;
  overflow-wrap: break-word;
}

.walletPolicySpeedCell > div:nth-child(5) {
  width: 10%;
  overflow-wrap: break-word;
}

.walletPolicySpeedCell > div:nth-child(6) {
  width: 20%;
  overflow-wrap: break-word;
}

.walletPolicySpeedCell > div:nth-child(7) {
  width: 6%;
  overflow-wrap: break-word;
  margin-right: 25px;
}

.walletPolicySpeedTitle {
  composes: walletPolicySpeedCell;
}

.walletPolicySpeedLine {
  composes: walletPolicySpeedCell;
  font-size: 16px;
  color: #202124;
  line-height: 22px;
  padding: 20px 0;
  align-items: center;
}

.walletPolicySpeedLine[marker='deleted'] {
  background: #f6f6f6;
  color: #b6b6b6;
}

.walletPolicyAddressAdd {
  display: flex;
}

.walletPolicyAddressAdd > :nth-child(1) {
  flex: 4;
}

.walletPolicyAddressAdd > :nth-child(1):hover > div:nth-child(1) {
  color: #3e3ef4;
  background: #ebebff;
  border: 1px solid #3e3ef4;
}

.walletPolicyAddressCoinCodeOptionWrapper {
  top: 0;
  transform: translateY(-50%);
}

.walletPolicyAddressAdd > :nth-child(2) {
  margin-left: 10px;
  flex: 20;
}

.walletPolicyAddressAdd > :nth-child(3) {
  flex: 3;
  height: 48px;
  width: 48px;
}

.walletPolicyAddressCell > div:nth-child(1) {
  padding-left: 25px;
  flex: 5;
}

.walletPolicyAddressCell > div:nth-child(2) {
  flex: 12;
  padding-right: 25px;
}

.walletPolicyAddressCell > div:nth-child(3) {
  flex: 5;
  padding-right: 25px;
}

.walletPolicyAddressCell > div:nth-child(4) {
  flex: 5;
}

.walletPolicyAddressCell > div:nth-child(5) {
  flex: 2;
  padding-right: 25px;
}

.walletPolicyAddressTitle {
  composes: walletPolicyAddressCell;
  margin-top: 20px;
}

.policyEffectiveTimeContainer {
  padding: 0 !important;
}

.walletPolicyAddressLine {
  composes: walletPolicyAddressCell;
  font-size: 16px;
  color: #202124;
  line-height: 22px;
  padding: 20px 0;
}

.walletPolicyAddressLine[marker='deleted'] {
  background: #f6f6f6;
  color: #b6b6b6;
}

.walletPolicyAddressLine > div:nth-child(4) {
  display: flex;
}

.walletPolicyAddressLine > div:nth-child(4) > * {
  margin-right: 5px;
}

.walletPolicyAddressLine > div:nth-child(4)[marker='effective'] {
  color: #11bf5a;
}

.walletPolicyAddressLine > div:nth-child(4)[marker='effecting'] {
  color: #2f75ec;
}

.walletPolicyAddressLine > div:nth-child(4)[marker='deprecating'] {
  color: #f2752e;
}

.walletPolicyApply {
  composes: walletPolicyAddressCell;
  padding: 10px 30px;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  border-radius: 4px;
}

.walletPolicyAction {
  font-weight: 500;
  font-size: 14px;
  color: #f63f45;
  line-height: 18px;
  text-decoration: underline;
}

.walletPolicyEmptyLine {
  font-size: 12px;
  color: #b5b5b6;
  line-height: 60px;
  height: 62px;
  justify-content: space-around;
}

.walletPolicyRevert {
  composes: walletPolicyApply;
  background: #f63f45;
  margin: 0 24px 0 0;
}

.flexWrapper {
  display: flex;
  align-items: center;
}

.walletSettingsContainer {
  padding: 16px 24px;
  background: #ffffff;
  box-shadow: 0px 6px 18px -8px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
}

.walletSettingsLine {
  height: 50px;
  line-height: 22px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.walletSettingsLine + .walletSettingsLine {
  margin-top: 30px;
}

.walletSettingsLine > div:nth-child(1) {
  width: 150px;
  font-size: 16px;
  color: #202124;
}

.walletSettingsInputNameContainer {
  width: 500px;
}

.walletSettingsInputContainer {
  width: 500px;
}

.walletSettingsStatusContainer {
  width: 200px;
  height: 48px;
  line-height: 48px;
  border-radius: 6px;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  color: #fff;
}

.walletSettingsStatusContainer[marker='active'] {
  background: #11bf5a;
}

.walletSettingsStatusContainer[marker='active_apply'] {
  background: #f2752e;
}

.walletSettingsStatusContainer[marker='frozen'] {
  background: #f63f45;
}

.walletSettingsStatusContainer[marker='frozen_apply'] {
  background: #f63f45;
}

.walletSettingsSubscriberLines {
  margin-top: 30px;
}

.walletSettingsSubscriberLine {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 18px;
  margin-bottom: 20px;
  margin-left: 150px;
}

.walletSettingsSubscriberAdd {
  width: 26px;
  height: 26px;
  margin-left: 24px;
}

.walletSettingsSubscriber {
  width: 300px;
  font-size: 14px;
  color: #202124;
}

.walletSettingsSubscriberRemove {
  font-size: 14px;
  font-weight: 500;
  color: #f63f45;
}

.coinContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px;
}

.coinContainer > div:nth-child(n + 2) {
  width: 100%;
}

.coinContainer > div:nth-child(2) {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  justify-content: space-between;
  margin-top: 30px;
}

.coinContainer > div:nth-child(3) {
  margin-top: 24px;
  position: relative;
}

.coinContainer > div:nth-child(3) > :nth-child(2) {
  display: flex;
  position: absolute;
  bottom: 25px;
  right: 0px;
}

.coinContainer > div:nth-child(3) > :nth-child(2) > :nth-child(2) {
  margin-left: 40px;
}

.coinTitle {
  line-height: 32px;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
}

.coinTitle > :nth-child(1) {
  font-weight: bold;
  font-size: 24px;
  color: #202124;
  cursor: pointer;
}

.coinTitle > :nth-child(3) {
  width: 8px;
  height: 12px;
  margin-left: 12px;
}

.coinTitle > :nth-child(4) {
  width: 32px;
  height: 32px;
  margin-left: 12px;
}

.coinTitle > :nth-child(5) {
  font-weight: 500;
  font-size: 16px;
  color: #000;
  margin-left: 12px;
}

.coinSettingContainer {
  background: #fff;
  padding: 16px 24px;
  box-shadow: 0px 6px 18px -8px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
}

.coinSettingsLine {
  height: 50px;
  line-height: 22px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 30px;
}

.coinSettingsLine > div:nth-child(1) {
  width: 200px;
  font-size: 16px;
  color: #202124;
}

.coinSettingsSubLine {
  height: 14px;
  line-height: 14px;
  margin-top: 20px;
  font-size: 12px;
  color: #b5b5b6;
}

.coinSettingsWarningLine {
  height: 30px;
  line-height: 20px;
  margin-top: 20px;
}

.coinSettingsWarningTimer {
  background: #f2752e;
  border-radius: 14px;
  padding: 0 9px;
  height: 28px;
  line-height: 28px;
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.coinSettingsWarningTimer > * {
  font-weight: 500;
  font-size: 12px;
  color: #fff;
  margin: 0 3px;
}

.coinSettingsSplitter {
  width: 100%;
  height: 1px;
  background: #ededed;
  margin-top: 20px;
}

.coinSettingsUnitInput {
  width: 200px;
}

.coinSettingsInputDescription {
  line-height: 14px;
  margin-left: 20px;
  font-size: 14px;
  color: #b5b5b6;
}

.coinSettingsInputDescriptionLinkContainer {
  line-height: 14px;
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.coinSettingsInputDescriptionLinkContainerLabel {
  font-size: 14px;
  color: #b5b5b6;
}

.coinSettingsInputDescriptionLink {
  font-size: 14px;
  margin: 0 5px;
}

.coinSettingsInputAddressContainer {
  width: 540px;
}

.coinSettingsSelect {
  height: 100%;
  width: 200px;
}

.coinSettingsButton {
  margin-top: 30px;
  width: 200px;
  font-size: 16px;
}

.coinTxsContent {
  margin-top: 20px;
}

.coinAddressesSearch {
  margin-top: 20px;
  width: 700px;
}

.coinAddressesPanel {
  margin-top: 20px;
}

.coinAddressesCell > :nth-child(1) {
  margin-left: 25px;
  flex: 700;
}

.coinAddressesCell > :nth-child(2) {
  flex: 400;
}

.coinAddressesCell > :nth-child(3) {
  flex: 200;
}

.coinAddressesCell > :nth-child(4) {
  flex: 100;
  margin-right: 25px;
}

.coinAddressesTitle {
  composes: coinAddressesCell;
  height: 44px;
}

.coinAddressesLine {
  composes: coinAddressesCell;
  height: 62px;
  font-size: 16px;
  color: #202124;
  display: flex;
  align-items: center;
}

.coinAddressesLineCopy {
  display: flex;
  align-items: center;
}

.coinAddressesLineCopy > :nth-child(1) {
  min-width: 450px;
  width: fit-content;
}

.coinAddressesLineCopy > :nth-child(2) {
  margin-left: 20px;
  width: 20px;
  height: 20px;
}

.apiKeyCreatePanel {
  display: flex;
  flex-direction: row;
  background: none;
  border: none;
  box-shadow: none;
}

.apiKeyCreatePanel > div:nth-child(1) {
  flex: 1;
  /* padding: 24px 0 0 0; */
}

.apiKeyCreatePanel[marker='hide'] > div:nth-child(1) {
  width: 400px;
  margin-left: 30px;
}

.apiKeyCreatePanel > div:nth-child(2) {
  flex: 1;
  margin: 64px 0 0 30px;
  padding: 16px 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 6px;
}

.apiKeyTip {
  font-size: 14px;
  color: #97999e;
  line-height: 22px;
  word-break: break-all;
}

.apiKeyCreatePanel > div:nth-child(2) > :nth-child(2) {
  font-size: 14px;
  color: #97999e;
  line-height: 22px;
  margin: 12px 0 0 0;
  display: flex;
}

a {
  color: #3e3ef4 !important;
}

.apiKeyCreatePanel > div:nth-child(2) > :nth-child(3) {
  font-size: 14px;
  color: #97999e;
  line-height: 22px;
  margin: 12px 0 0 0;
}

.apiKeyCreatePanel > div:nth-child(2) > :nth-child(4) {
  font-size: 14px;
  color: #f63f45;
  line-height: 22px;
  margin: 12px 0 0 0;
}

.traderHintContainer {
  font-size: 16px;
  color: #202124;
  line-height: 28px;
}

.traderHintContainerTitle {
  font-weight: bold;
  font-size: 18px;
  color: #202124;
  line-height: 24px;
}

.apiKeyCreateTitle {
  font-size: 16px;
  font-weight: 600;
  color: #202124;
  line-height: 24px;
}

.apiKeyCreateType {
  font-size: 14px;
  font-weight: 500;
  color: #202124;
  line-height: 22px;
}

.apiKeyCreateButton {
  width: 108px;
  margin: 24px 0;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
}

.apiKeyListLabelTitle {
  font-size: 16px;
  font-weight: 600;
  color: #202124;
  line-height: 24px;
}

.apiKeyListPanel {
  margin-top: 16px;
}

.apiKeyListCells > div:nth-child(1) {
  margin-left: 25px;
  flex: 150;
}

.apiKeyListCells > div:nth-child(2) {
  flex: 100;
  padding: 0 20px 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.apiKeyListCells > div:nth-child(3) {
  flex: 80;
}

.apiKeyListCells > div:nth-child(4) {
  flex: 100;
  padding: 0 20px 0 0;
}

.apiKeyListCells > div:nth-child(5) {
  flex: 100;
  padding: 0 20px 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.apiKeyListCells > div:nth-child(6) {
  flex: 150;
}

.apiKeyListCells > div:nth-child(7) {
  flex: 80;
}

.apiKeyListCells > div:nth-child(8) {
  margin-right: 25px;
  flex: 80;
}

.apiKeyListIPsTooltip {
  margin-bottom: 5px;
  margin-left: -50px;
}

.apiKeyListIP {
  font-size: 14px;
  color: #202124;
  line-height: 22px;
  height: 22px;
  padding: 0px 10px;
}

.apiKeyDescription {
  font-size: 14px;
  line-height: 22px;
  color: #202124;
  padding: 0px 10px;
  word-wrap: break-word;
  word-break: break-all;
}

.singleLineToolTip {
  max-width: none;
}

.apiKeyListTitle {
  composes: apiKeyListCells;
}

.apiLabelDescription {
  margin: 24px 0 8px 8px;
  font-size: 12px;
  font-weight: 400;
  color: #202124;
  opacity: 0.3;
  line-height: 22px;
}

.apiLabel {
  margin: 24px 0 8px;
  color: #202124;
  font-size: 14px;
  font-weight: 500;
  opacity: 0.65;
  line-height: 22px;
}

.apiKeyTypeLabel {
  composes: apiLabel;
  margin: 16px 0 8px;
}

div.apiKeyListLineText {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

.apiKeyListLine {
  composes: apiKeyListCells;
}

.apiKeyListLine > div {
  font-size: 14px;
  font-weight: 500;
  color: #202124;
  line-height: 22px;
}

.apiKeyListLine[marker='inactive'],
.apiKeyListLine[marker='expired'] {
  background: #f6f6f6 !important;
}

.apiKeyListLine[marker='inactive'] > div,
.apiKeyListLine[marker='expired'] > div {
  color: #b6b6b6 !important;
}

.apiKeyListLine[marker='active'] > .apiKeyListLineStatus {
  color: #11bf5a !important;
}

.apiKeyListLine[marker='activating'] > .apiKeyListLineStatus {
  color: #f2752e !important;
}

.circle {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin: 0 8px 0 0;
}

.circle[marker='active'] {
  background: #11bf5a;
}

.circle[marker='activating'] {
  background: #f2752e;
}

.circle[marker='inactive'],
.circle[marker='expired'] {
  background: #b6b6b6;
}

.apiKeyListLineAction {
  display: flex;
  flex-direction: row;
  height: 22px;
  line-height: 22px;
}

.apiKeyListLineAction > div {
  font-size: 14px;
  font-weight: 500;
}

.apiKeyListLineActionEdit {
  color: #3e3ef4;
  margin: 0 20px 0 0;
}

.apiKeyListLineActionDelete {
  color: #f63f45;
}

.apiModalLabel {
  font-size: 14px;
  color: #202124;
  line-height: 22px;
}

.apiCreateResultModal {
  width: 600px;
  display: flex;
  flex-direction: column;
}

.apiCreateResultModalLabel {
  composes: apiModalLabel;
  padding: 0 50px;
  box-sizing: border-box;
  fint-size: 14px;
  color: #202124;
}

.apiCreateResultModalLabels {
  composes: apiModalLabel;
  padding: 0 50px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  fint-size: 14px;
}

.apiCreateResultModalLabels > :nth-child(1) {
  color: #202124;
}

.apiCreateResultModalLabels > :nth-child(2) {
  color: #f63f45;
  margin-left: 10px;
}

.apiCreateResultModalWarningLine {
  opacity: 0.5;
  font-size: 14px;
  color: #202124;
  line-height: 18px;
  padding: 0 50px;
  margin-top: 15px;
}

.apiCreateResultModalButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-top: 30px;
  margin-bottom: 20px;
}

.apiCreateResultModalButton > div {
  width: 300px;
  font-size: 16px;
}

.apiCreateResultModalContent {
  margin: 10px 50px 24px 50px;
  word-break: break-all;
  font-size: 14px;
  font-weight: 500;
  color: #202124;
  padding: 14px 15px;
  border: 1px solid #ededed;
  border-radius: 6px;
}

.apiEditModalLabel {
  composes: apiModalLabel;
}

.apiEditModalInput {
  margin: 10px 0 24px 0;
}

.apiEditModalButton {
  width: 400px;
}

.apiCallbackTitle {
  /* margin-top: 30px; */
  /* height: 24px; */
  display: flex;
  align-items: center;
}

.apiCallbackTitle > div:nth-child(1) {
  font-weight: bold;
  font-size: 18px;
  color: #202124;
}
.apiCallHref {
  color: fontRoyalBlue;
}
.apiCallbackTitle > div:nth-child(2) {
  word-break: break-all;
  font-size: 14px;
  line-height: 24px;
  color: fontBombay;
  padding: 0 15px;
}
.apiCallbackSubTitle {
  display: flex;
  margin-top: 12px;
}
.apiCallbackSubTitleValue {
  color: fontBombay;
  margin-left: 5px;
}
.displayInline {
  display: inline;
}
.apiCallbackListPanel {
  margin-top: 20px;
}

.apiCallbackListCells > div:nth-child(1) {
  margin-left: 25px;
  flex: 1;
}

.apiCallbackListCells > div:nth-child(2) {
  margin-left: 35px;
  flex: 2;
  overflow: hidden;
}

.apiCallbackListCells > div:nth-child(3) {
  margin-left: 30px;
  flex: 1;
}

.apiCallbackListCells > div:nth-child(4) {
  margin-left: 35px;
  flex: 1;
}

.apiCallbackListTitle {
  composes: apiCallbackListCells;
}

.apiCallbackListLine {
  composes: apiCallbackListCells;
}

.apiCallbackListLine > div {
  font-size: 16px;
  line-height: 22px;
}

.apiCallbackListLine > div[marker='active'] {
  color: #11bf5a;
}

.apiCallbackListLineAction {
  display: flex;
  flex-direction: row;
  height: 22px;
  line-height: 22px;
}

.apiCallbackListLineAction > div {
  font-size: 14px;
  font-weight: 500;
}

.apiCallbackListLineActionEdit {
  color: #3e3ef4;
}

.apiCallbackListLineActionDelete {
  color: #f63f45;
  margin-left: 19px;
}

.apiCallbackSubLine {
  display: flex;
  align-items: center;
  margin-left: 25px;
  margin-bottom: 20px;
}

.apiCallbackSubLine > :nth-child(1) {
  height: 18px;
  width: 18px;
}

.apiCallbackSubLine > :nth-child(2) {
  line-height: 22px;
  font-size: 14px;
  color: #b5b5b6;
  margin-left: 15px;
}

.apiCallbackSubLine > :nth-child(3) {
  line-height: 22px;
  font-size: 14px;
  margin-left: 5px;
  opacity: 0.8;
}

.apiCallbackModalLabel {
  composes: apiModalLabel;
}

.apiCallbackModalTypeLabel {
  composes: apiModalLabel;
  margin-bottom: 24px;
}

.apiCallbackModalInput {
  margin: 10px 0 24px 0;
}

.apiCallbackModalButton {
  margin: 30px 0 20px 0;
  font-size: 16px;
}

.apiPushTitle {
  margin-top: 30px;
  line-height: 24px;
  height: 24px;
  font-weight: bold;
  font-size: 18px;
  color: #202124;
}
.apiPushSearch {
  display: flex;
  width: 705px;
  margin-top: 15px;
}
.apiPushListPanel {
  margin-top: 20px;
}

.apiPushListCells > div:nth-child(1) {
  margin-left: 25px;
  flex: 200;
}

.apiPushListCells > div:nth-child(2) {
  margin-left: 40px;
  flex: 400;
  overflow: hidden;
  overflow-wrap: break-word;
}

.apiPushListCells > div:nth-child(3) {
  margin-left: 30px;
  flex: 160;
}

.apiPushListCells > div:nth-child(4) {
  margin-left: 35px;
  flex: 90;
}

.apiPushListCells > div:nth-child(5) {
  margin-left: 35px;
  flex: 200;
}

.apiPushListCells > div:nth-child(6) {
  margin-left: 35px;
  flex: 160;
}

.apiPushListCells > div:nth-child(7) {
  margin-left: 35px;
  flex: 95;
  margin-right: 25px;
}

.apiPushListTitle {
  composes: apiPushListCells;
  align-items: center;
  line-height: 1.5;
}

.apiPushListLine {
  composes: apiPushListCells;
}

.apiPushListLine > div {
  font-size: 16px;
  line-height: 22px;
}

.apiPushDetailModal {
  width: 650px;
  padding-bottom: 20px;
}

.apiPushDetailLine {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ededed;
  min-height: 62px;
  padding: 20px 0;
  margin: 0 20px;
  box-sizing: border-box;
}

.apiPushDetailLine > div:nth-child(1) {
  flex: 1;
  line-height: 22px;
  font-size: 14px;
  color: #a6a7a8;
}

.apiPushDetailLine > div:nth-child(2) {
  text-align: right;
  word-break: break-all;
  height: fit-content;
  flex: 2;
  line-height: 24px;
  font-size: 16px;
  color: #202124;
  font-weight: 500;
}

.apiPushDetailLine[marker='highlight'] > div:nth-child(2) {
  color: #3e3ef4;
}

.apiPushDetailLine[marker='success'] > div:nth-child(2) {
  color: #11bf5a;
}

.apiPushDetailLine[marker='pending'] > div:nth-child(2) {
  color: #2f75ec;
}

.apiPushDetailLine[marker='fail'] > div:nth-child(2) {
  color: #f63f45;
}

.apiPushDetailModalContent {
  word-break: break-all;
  font-size: 14px;
  font-weight: 500;
  color: #202124;
  padding: 14px 15px;
  border: 1px solid #ededed;
  border-radius: 6px;
  max-height: 200px;
  overflow-y: auto;
}

.apiPushDetailButton {
  width: 50%;
}

.displayNone {
  display: none;
}

.buttonGap {
  margin-right: 28px;
}

.fixedButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  box-shadow: 0px -3px 6px 0px rgba(0, 0, 0, 0.04);
  padding: 20px 30px 20px 20%;
}

.whiteListTable {
  margin: 0 0 30px 0;
}

.applyRuleTip {
  font-size: 14px;
  font-weight: 500;
  color: #b5b5b6;
  line-height: 20px;
}

.effectiveCointainerMargin {
  margin-right: 12px;
}

.revertBoxContainer {
  justify-content: center !important;
}

.revertBoxText {
  font-size: 12px !important;
  line-height: 20px !important;
}

.infoImg {
  width: 14px;
  height: 14px;
}

.mediumImg {
  width: 16px;
  height: 16px;
}

.flexRow {
  display: flex;
  align-items: center;
}

.readOnlyInput {
  composes: apiEditModalInput;

  background: rgba(24, 28, 39, 0.04);

  font-size: 14px;
  font-weight: 600;
  color: #202124;
  line-height: 22px;
}

.modalButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 16px;
  width: 100%;
  padding: 0 60px;
}

.modalButtons > div {
  flex: 1;
  max-width: 200px;
}

.modalButtons > div:nth-child(1) {
  background: #fff;
  color: #202124;
  box-shadow: none;
  border: 1px solid #d4d3d3;
}

.textContainer {
  white-space: pre-wrap;
}

.remarkTagContainer {
  padding: 30px 20px 10px;
}

.switchRemarkTag {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  color: #3e3ef4;
  line-height: 18px;
  cursor: pointer;
}

.switchOn::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-bottom: 5px solid #3e3ef4;
  margin: 0 0 5px 10px;
}

.switchOff::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-top: 5px solid #3e3ef4;
  margin: 5px 0 0 10px;
}

.modalLabel {
  margin: 8px 0 0 0;
  flex: 1;
}

.coinTxsContainer {
  background: #fbfbfd !important;
}

.walletTxs,
.coinTxs {
  margin: 0 0 200px 0 !important;
}
