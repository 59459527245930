.otcContent {
  display: flex;
  flex-direction: column;
}

.otcContent > div:nth-child(1) {
  height: 373px;
  display: flex;
  flex-direction: column;
  padding: 20px 30px 30px 30px;
  box-sizing: border-box;
  align-items: center;
}

.otcContent > div:nth-child(2) {
  margin: 30px 0 20px 0;
  font-weight: bold;
  font-size: 18px;
  color: #202124;
  line-height: 24px;
  display: flex;
  align-items: center;
}

.otcContent > div:nth-child(2) > :nth-child(2) {
  height: 18px;
  margin-left: 20px;
}

.otcContent > div:nth-child(3) {
  flex: 1;
}

.otcContent > div:nth-child(4) {
  margin: 60px 0 10px 0;
}

.otcTitle {
  width: 100%;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.otcTitle > div:nth-child(1) {
  font-weight: bold;
  font-size: 24px;
  color: #202124;
  line-height: 32px;
  display: flex;
  align-items: center;
}

.otcTitle > div:nth-child(1) > :nth-child(2) {
  margin-left: 50px;
  min-width: 300px;
}

.otcTitle > div:nth-child(2) {
  background: #f2752e;
  border-radius: 14px;
  display: flex;
  flex-direction: row;
  padding: 6px 15px;
  font-weight: 500;
  font-size: 12px;
  color: #fff;
  text-align: center;
  line-height: 16px;
}

.otcOTPTimer {
  margin: 0 5px;
}

.otcResetOTP {
  font-weight: 500;
  font-size: 12px;
  color: #3e3ef4;
  line-height: 16px;
  margin-left: 5px;
}

.otcExchange {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 30px;
}

.otcExchangeImg {
  width: 30px;
  height: 30px;
  margin: 35px 25px 0 25px;
}

.otcExchangePay,
.otcExchangeGet {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.otcExchangePay > * {
  width: 100%;
}

.otcExchangePay > :nth-child(1) {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 14px;
  line-height: 18px;
}

.otcExchangePay > :nth-child(1) > :nth-child(1) {
  color: #202124;
}

.otcExchangePay > :nth-child(1) > :nth-child(2) {
  color: #a6a7a8;
  display: flex;
  flex-direction: row;
}

.otcExchangePay > :nth-child(1) > :nth-child(2) > * {
  margin: 0 2px;
}

.otcExchangePay > :nth-child(1) > :nth-child(2) > :nth-child(n + 3) {
  color: #f2752e;
}

.otcExchangePay > :nth-child(2) {
  margin: 9px 0 15px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.otcExchangePay > :nth-child(3) {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.otcExchangePay > :nth-child(3) > :nth-child(1) {
  font-size: 12px;
  color: #f63f45;
  line-height: 18px;
}

.otcExchangePay > :nth-child(3) > :nth-child(2) {
  font-size: 14px;
  color: #a6a7a8;
  line-height: 18px;
  display: flex;
  flex-direction: row;
}

.otcExchangePay > :nth-child(3) > :nth-child(2) > * {
  margin: 0 2px;
}

.otcExchangePay > :nth-child(3) > :nth-child(2) > :nth-child(3) {
  color: #3e3ef4;
}

.otcExchangePayInput[marker='error'] input {
  border: 1px solid #f63f45;
}

.otcExchangePaySelect {
  min-width: 150px;
  margin-left: 10px;
}

.otcExchangeGet > :nth-child(1) {
  font-size: 14px;
  line-height: 18px;
  color: #202124;
}

.otcExchangeGet > :nth-child(2) {
  margin: 9px 0 15px 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.otcExchangeButton {
  margin: 40px 0 30px 0;
  font-size: 14px;
  min-width: 200px;
}

.otcExchangeHint {
  width: 100%;
  opacity: 0.5;
  font-size: 14px;
  color: #202124;
  line-height: 18px;
}

.otcTradesCells > :nth-child(1) {
  margin-left: 25px;
  flex: 200;
}

.otcTradesCells > :nth-child(2) {
  flex: 120;
}

.otcTradesCells > :nth-child(3) {
  flex: 56;
  justify-content: flex-start;
}

.otcTradesCells > :nth-child(4) {
  flex: 200;
}

.otcTradesCells > :nth-child(5) {
  flex: 120;
}

.otcTradesCells > :nth-child(6) {
  flex: 200;
}

.otcTradesCells > :nth-child(7) {
  flex: 220;
}

.otcTradesCells > :nth-child(8) {
  flex: 250;
  word-break: break-all;
  padding: 0 10px;
}

.otcTradesCells > :nth-child(9) {
  flex: 100;
  margin-right: 25px;
}

.otcTradesCells > :nth-child(9)[marker='success'] {
  color: #11bf5a;
}

.otcTradesCells > :nth-child(9)[marker='pending'] {
  color: #f2752e;
}

.otcTradesCells > :nth-child(9)[marker='failed'] {
  color: #f63f45;
}

.otcTradesTitle {
  composes: otcTradesCells;
}

.otcTradesLine {
  composes: otcTradesCells;
  font-size: 16px;
  color: #202124;
  line-height: 22px;
  height: 60px;
  align-items: center;
}

.otcTradesEmptyLine {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #b5b5b6;
  text-align: center;
  line-height: 14px;
}

.otcTradesLineImg {
  width: 24px;
  height: 24px;
}

.otpContent {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
}

.otpContent > :nth-child(1) {
  margin-top: 120px;
  width: 80px;
  height: 80px;
}

.otpContent > :nth-child(2) {
  font-weight: bold;
  font-size: 24px;
  color: #202124;
  text-align: center;
  line-height: 32px;
  margin-top: 40px;
}

.otpContent > :nth-child(3) {
  margin-top: 40px;
}

.otpContent > :nth-child(4) {
  margin-top: 40px;
}

.otpContent > :nth-child(5) {
  margin-top: 35px;
  font-size: 14px;
  color: #b5b5b6;
  line-height: 14px;
  display: flex;
  align-items: center;
}

.otpContent > :nth-child(5) > :nth-child(1) {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

.otcConfirmModal {
  width: 480px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.otcConfirmTimer {
  display: flex;
  font-size: 14px;
  color: #f2752e;
  line-height: 18px;
  margin-top: -40px;
  margin-bottom: 10px;
}

.otcConfirmTimer > :nth-child(2) {
  margin-left: 5px;
}

.otcConfirmLine {
  width: 85%;
  height: 60px;
  display: flex;
  align-items: center;
  line-height: 22px;
  border-bottom: 1px solid #ededed;
  justify-content: space-between;
}

.otcConfirmLine > :nth-child(1) {
  font-size: 14px;
  color: #a6a7a8;
}

.otcConfirmLine > :nth-child(2) {
  font-weight: 500;
  font-size: 16px;
  color: #202124;
}

.otcConfirmButton {
  margin: 40px 0 20px 0;
  width: 85%;
  font-size: 18px;
}

.codeContainer {
  position: relative;
}

.paste {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(100%, -50%);
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  color: #3e3ef4;
  line-height: 20px;
  padding-left: 30px;
}
