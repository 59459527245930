@import 'common/fonts.scss';

.container {
  height: 100%;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 6px 18px -8px rgba(0, 0, 0, 0.08);

  display: flex;
  position: relative;
}

.spinContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(#fff, 0.7);
  z-index: 10;

  display: flex;
  align-items: center;
  justify-content: center;
}

.leftSide {
  background: #f9fafd;
  flex-basis: 40%;
  min-width: 400px;
  overflow: auto;
  padding: 24px;
}

.rightSide {
  background: #fff;
  flex: 1;

  position: relative;
}

.item {
  background: #fff;
  border-radius: 4px;
  border: 1px solid rgba(24, 28, 39, 0.15);
  padding: 20px;
  position: relative;

  margin: 0 0 16px 0;
  cursor: pointer;
}

.statusIcon {
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 60px;

  &.inProgressStatusIcon {
    width: 82px;
    height: 24px;
  }
}

.activeItem {
  border: 1px solid #3e3ef4;
  box-shadow: 0px 6px 18px -8px rgba(0, 0, 0, 0.08);
}

.infoLine {
  display: flex;
  align-items: center;
  margin: 16px 0 0 0;
}

.nameWrapper {
  display: flex;
  align-items: center;
}

.name {
  color: #181c27;
  @include fontSize-14;
  @include mediumFont;
  margin: 0 0 0 8px;
}

.verticalLine {
  width: 1px;
  height: 12px;
  background: rgba(24, 28, 39, 0.1);
  margin: 0 8px;
}

.timeText {
  color: rgba(#181c27, 0.6);
  @include fontSize-12;
  @include regularFont;
}
