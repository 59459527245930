.posContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.posTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 28px;
  width: 100%;
}

.posTitle > :nth-child(1) {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.posTitle > :nth-child(1) > :nth-child(1) {
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  height: 32px;
  color: #202124;
}

.posTitle > :nth-child(1) > :nth-child(2) {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #202124;
  min-width: 300px;
  margin-left: 30px;
}

.posTitle > :nth-child(2) {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.posTitle > :nth-child(2) > div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.posTitle > :nth-child(2) > :nth-child(1) > :nth-child(1) {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  height: 24px;
  color: #202124;
}

.posTitle > :nth-child(2) > :nth-child(1) > :nth-child(2) {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  height: 24px;
  color: #1bc49d;
}

.posTitle > :nth-child(2) > :nth-child(2) > div {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  height: 24px;
  color: #202124;
}

.posTitle > :nth-child(2) > div > :nth-child(2) {
  margin-left: 10px;
}

.posProduct {
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.posProduct > div {
  width: 400px;
  height: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 30px;
  margin-bottom: 30px;
}

.posProduct > div > :nth-child(1) {
  width: 60px;
  height: 60px;
  margin-top: 20px;
}

.posProduct > div > :nth-child(2) {
  height: 22px;
  font-size: 18px;
  font-weight: bold;
  color: #202124;
  line-height: 22px;
  margin-top: 10px;
}

.posProduct > div > :nth-child(3) {
  height: 36px;
  font-size: 30px;
  font-weight: bold;
  color: #1bc49d;
  line-height: 36px;
  margin-top: 10px;
}

.posProduct > div > :nth-child(4) {
  height: 18px;
  font-size: 14px;
  font-weight: 400;
  color: #202124;
  line-height: 18px;
  margin-top: 4px;
}

.posProduct > div > :nth-child(5) {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  height: 32px;
}

.posProduct > div > :nth-child(5) > div {
  background: #f6f6f6;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #a6a7a8;
  padding: 7px 12px;
  margin: 0px 12px;
}

.posProduct > div > :nth-child(6) {
  font-size: 14px;
  margin-top: 20px;
  width: 200px;
  height: 48px;
}

.posProductStakeModal {
  width: 660px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.posProductRate {
  height: 36px;
  font-size: 30px;
  font-weight: bold;
  color: #1bc49d;
  line-height: 36px;
  margin-top: -16px;
}
.posProductSubTitle {
  margin-top: 9px;
  height: 18px;
  font-size: 14px;
  font-weight: 400;
  color: #a6a7a8;
  line-height: 18px;
}
.posProductTimeLine {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  padding: 0px 40px;
  box-sizing: border-box;
  width: 100%;
  align-items: center;
}
.posProductDescription {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  padding: 0px 40px;
  box-sizing: border-box;
  width: 100%;
}
.posProductDescription > div {
  margin-top: 12px;
  line-height: 26px;
}
.posProductAmount {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 40px;
  box-sizing: border-box;
  width: 100%;
}

.posProductStakeModalTime {
  width: 150px;
  height: 70px;
  margin: 0 -50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}

.posProductStakeModalTime > :nth-child(1) {
  color: #353535;
  height: 18px;
}

.posProductStakeModalTime > :nth-child(2) {
  width: 12px;
  height: 12px;
  background: #fff;
  border: 3px solid #3e3ef4;
  border-radius: 6px;
  box-sizing: border-box;
}

.posProductStakeModalTime > :nth-child(3) {
  color: #a6a7a8;
  height: 18px;
}

.posProductStakeModalTimeLine {
  height: 2px;
  flex: 1;
  background: #ededed;
}

.posProductAmount > :nth-child(1) {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 25px;
}
.posProductAmount > :nth-child(2) {
  flex: 1;
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.posProductAmount > :nth-child(2) > :nth-child(1) {
  width: 100%;
}
.posProductAmount > :nth-child(2) > :nth-child(2) {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 4px;
}

.posProductStakeModal
  > :nth-child(6)
  > :nth-child(2)
  > :nth-child(2)
  > :nth-child(1) {
  font-size: 14px;
  opacity: 0.3;
  color: #202124;
  text-align: center;
}

.posProductStakeModal
  > :nth-child(6)
  > :nth-child(2)
  > :nth-child(2)
  > :nth-child(2) {
  font-size: 14px;
  color: #3e3ef4;
  margin-left: 5px;
}

.posProductStakeModalLink {
  margin-top: 10px;
  width: 100px;
  height: 18px;
  font-size: 14px;
  font-weight: 400;
  color: #3e3ef4;
  line-height: 18px;
}
.posProductSendAll {
  color: #3e3ef4;
}
.posProductStakeModalSubmitBtn {
  font-size: 18px;
  width: 400px;
}

.posProductUnstakeModal {
  width: 560px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.posProductUnstakeModalContent,
.posProductUnstakeModalFee {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
  padding: 0px 40px;
  box-sizing: border-box;
  width: 100%;
}

.posProductUnstakeModalContent > :nth-child(1) {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}
.posProductUnstakeModalContent > :nth-child(2) {
  flex: 1;
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.posProductUnstakeModalContent > :nth-child(2) > :nth-child(1) {
  width: 100%;
}
.posProductUnstakeModalContent > :nth-child(2) > :nth-child(2) {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 4px;
}
.posProductUnstakeModalContent > :nth-child(2) > :nth-child(2) > :nth-child(1) {
  font-size: 14px;
  opacity: 0.3;
  color: #202124;
  text-align: center;
}
.posProductUnstakeModalContent > :nth-child(2) > :nth-child(2) > :nth-child(2) {
  font-size: 14px;
  color: #3e3ef4;
  margin-left: 5px;
}
.posProductUnstakeModalFee > :nth-child(1) {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}
.posProductUnstakeModalFee > :nth-child(2) {
  color: #ff6d26;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin-left: 30px;
}
.posProductUnstakeModalSubmitBtn {
  width: 450px;
}

.posStakingAutoStakeModal {
  width: 480px;
  display: flex;
  flex-direction: column;
}

.posStakingAutoStakeModalLine {
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  margin-left: 140px;
}

.posStakingAutoStakeModalLine:nth-child(2) {
  margin-top: -18px;
}

.posStakingAutoStakeModalLine > :nth-child(1) {
  width: 18px;
  height: 18px;
}

.posStakingAutoStakeModalLine > :nth-child(2) {
  height: 18px;
  font-size: 14px;
  font-weight: bold;
  color: #202124;
  line-height: 18px;
  margin-left: 15px;
}

.posStakingAutoStakeModalButton {
  font-size: 18px;
  margin: 30px 40px 20px 40px;
}

.posStaking {
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  flex: 1;
}

.posStaking > div {
  width: 540px;
  height: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 30px;
  margin-bottom: 30px;
  position: relative;
}

.posStaking > div > :nth-child(1) {
  width: 60px;
  height: 60px;
  margin-top: 20px;
}

.posStaking > div > :nth-child(2) {
  height: 22px;
  font-size: 18px;
  font-weight: bold;
  color: #202124;
  line-height: 22px;
  margin-top: 10px;
}

.posStaking > div > :nth-child(3) {
  height: 22px;
  font-size: 18px;
  font-weight: bold;
  color: #f2752e;
  line-height: 22px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
}

.posStaking > div > :nth-child(3) > div {
  margin: 0 3px;
}

.posStaking > div > :nth-child(4) {
  height: 18px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
}

.posStaking > div > :nth-child(4) > :nth-child(1) {
  color: #202124;
}

.posStaking > div > :nth-child(4) > :nth-child(n + 1) {
  color: #1bc49d;
}

.posStaking > div > :nth-child(4) > div {
  margin: 0 3px;
}

.posStaking > div > :nth-child(5) {
  height: 18px;
  font-size: 14px;
  font-weight: 400;
  color: #3e3ef4;
  line-height: 18px;
  margin-top: 20px;
}

.posStaking > div > :nth-child(6) {
  font-size: 14px;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.posStaking > div > :nth-child(6) > div {
  margin: 0px 10px;
  width: 200px;
}

.posStaking > div > :nth-child(7) {
  position: absolute;
  right: 0;
  top: 0;
  height: 0;
  width: 0;
  border-top: 120px solid #f6f6f6;
  border-left: 120px solid transparent;
}

.posStaking > div > :nth-child(8) {
  position: absolute;
  right: 0;
  top: 0;
  height: 120px;
  width: 120px;
  font-size: 10px;
  font-weight: 500;
  color: #f2752e;
  line-height: 16px;
  padding-top: 10px;
  padding-right: 10px;
  text-align: right;
}

.posStaking > div > :nth-child(8) > :nth-child(4) {
  color: #3e3ef4;
  margin-top: 5px;
}

.posUnstaking {
  display: flex;
  width: 100%;
  flex-direction: column;
  font-size: 16px;
  margin-top: 20px;
}

.posUnstakingTableCells {
  align-items: center;
}

.posUnstakingTableCells > div:nth-child(1) {
  margin-left: 25px;
  flex: 60;
  justify-content: flex-start;
}

.posUnstakingTableCells > div:nth-child(2) {
  flex: 100;
}

.posUnstakingTableCells > div:nth-child(3) {
  flex: 120;
}

.posUnstakingTableCells > div:nth-child(4) {
  flex: 100;
  margin-right: 25px;
}

.posUnstakingTableTitle {
  composes: posUnstakingTableCells;
}

.posUnstakingTableLine {
  composes: posUnstakingTableCells;
  line-height: 16px;
}

.posUnstaking > div > div:nth-child(3) {
  color: #008000;
}

.posHistory {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.posHistorySearchPanel {
  display: flex;
  flex-direction: column;
  width: 1000px;
}

.posHistoryTablePanel {
  margin-top: 30px;
}

.posHistoryTableCells {
  align-items: center;
}

.posHistoryTableCells > div:nth-child(1) {
  margin-left: 25px;
  flex: 60;
  justify-content: flex-start;
}

.posHistoryTableCells > div:nth-child(2) {
  flex: 100;
}

.posHistoryTableCells > div:nth-child(3) {
  flex: 120;
}

.posHistoryTableCells > div:nth-child(4) {
  flex: 100;
}

.posHistoryTableCells > div:nth-child(5) {
  flex: 110;
  margin-right: 25px;
}

.posHistoryTableCell[marker='unstake'] {
  color: #008000;
}

.posHistoryTableCell[marker='stake'] {
  color: #00f;
}

.posHistoryTableCell[marker='reward'] {
  color: #00f;
}

.posHistoryTableCell[marker='fee'] {
  color: #008000;
}

.posHistoryTableTitle {
  composes: posHistoryTableCells;
}

.posHistoryTableLine {
  composes: posHistoryTableCells;
  line-height: 16px;
}

.posHistoryTableCell {
  font-size: 14px;
  display: flex;
}

.posHistoryTableCellTypeImg {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.unstakingNotFound {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.unstakingNotFound > :nth-child(1) {
  margin-top: 70px;
  width: 250px;
  height: 112px;
}

.unstakingNotFound > :nth-child(2) {
  margin-top: 57px;
  height: 19px;
  font-size: 16px;
  font-weight: 400;
  color: #202124;
  line-height: 19px;
  display: flex;
}

.posNotFound {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.posNotFound > :nth-child(1) {
  margin-top: 70px;
  width: 250px;
  height: 112px;
}

.posNotFound > :nth-child(2) {
  margin-top: 57px;
  height: 19px;
  font-size: 16px;
  font-weight: 400;
  color: #202124;
  line-height: 19px;
  display: flex;
}

.posNotFound > :nth-child(2) > div {
  margin: 0 5px;
}

.posNotFound > :nth-child(2) > div:nth-child(2) {
  color: #3e3ef4;
}
