@value colors: './color.module.css';
@value backgroundWhite, fontBlack, royalBlue from colors;

:global .ant-tooltip-inner {
  background-color: #fff;
  color: fontBlack;
}
:global .ant-tooltip-arrow-content {
  background-color: backgroundWhite;
  color: fontBlack;
  width: 8px;
  height: 8px;
}
:global .ant-message {
  z-index: 999999;
}

:global .ant-radio-checked:not(.ant-radio-disabled) .ant-radio-inner {
  border-color: royalBlue !important ;
}

:global .ant-radio-checked:not(.ant-radio-disabled) .ant-radio-inner:after {
  background-color: royalBlue !important;
}

:global .ant-radio:hover .ant-radio-inner {
  border-color: royalBlue !important;
}

:global .ant-radio-input:focus + .ant-radio-inner {
  box-shadow: 0 0 0 3px rgba(62, 62, 244, 0.08) !important;
}

:global .ant-picker-focused {
  border: 1px solid #3e3ef4 !important;
}

:global .ant-picker {
  box-shadow: none !important;
}

:global .ant-picker:hover {
  border: 1px solid #3e3ef4 !important;
}

:global .ant-picker-active-bar {
  background: #3e3ef4 !important;
}

:global .ant-picker-clear {
  right: 16px !important;
}

:global .ant-popover-inner-content {
  padding: 12px !important;
}

:global .ant-input-number:hover {
  border-color: #3e3ef4 !important;
}

:global .ant-input-number-focused {
  border-color: #3e3ef4 !important;
  box-shadow: none !important;
}

:global .ant-input-number-focused input:focus {
  border: none !important;
}
