.label {
  display: block;
  box-sizing: border-box;
  position: relative;
}
.labelIsRequired:after {
  content: '*';
  display: inline-block;
  position: absolute;
  top: 0;
  right: -10px;
  font-size: 12px;
  color: #f63f45;
}

.label:hover .tooltip {
  display: flex;
}

.warningLabel {
  color: #f07300;
  letter-spacing: 0.2px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.warningLabel > :nth-child(1) {
  width: 18px;
  height: 18px;
  margin-top: 5px;
}

.warningLabel > :nth-child(2) {
  flex: 1;
  margin-left: 13px;
}

.alertLabel {
  color: #f63f45;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.alertLabel > :nth-child(1) {
  height: 16px;
  width: 16px;
}

.alertLabel > :nth-child(2) {
  flex: 1;
  margin-left: 8px;
  font-size: 12px;
}

.infoLabel {
  color: #a6a7a8;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.infoLabel > :nth-child(1) {
  height: 18px;
  width: 18px;
}

.infoLabel > :nth-child(2) {
  flex: 1;
  margin-left: 10px;
  font-size: 14px;
}

.copyLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.copyLabelPrefix {
  margin-right: 5px;
}

.copyLabelText {
  flex: 1;
}

.copyLabelImg {
  width: 20px;
  height: 20px;
  margin-left: 13px;
}

.tooltip {
  display: none;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 100%;
  right: 100%;
  z-index: 999;
}

.tooltip > div {
  background: #fff;
}

.tooltip > div:nth-child(1) {
  border-radius: 3px;
  background: #fff;
  padding: 10px;
  border: 1px solid #f2f2f2;
  box-shadow: 0 -6px 30px 0 rgba(0, 0, 0, 0.1);
}

.tooltip > div:nth-child(2) {
  border-right: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
  background: #fff;
  transform: translateX(0) translateY(-50%) rotateZ(45deg);
  width: 15px;
  height: 15px;
}

.qrLabel {
  position: relative;
}

.qrLabel > div:nth-child(2) {
  width: 100%;
  height: 100%;
}

.qrLabel .tooltip {
  bottom: 20px;
  left: -50px;
}

.qrLabel .tooltip > :nth-child(1) {
  padding: 0;
}

.qrLabelTooltipImg {
  width: 120px;
  height: 120px;
}

.qrLabel:hover .tooltip {
  display: flex;
}

.button,
.copy {
  cursor: pointer;
  user-select: none;
  box-sizing: border-box;
}

.input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  position: relative;
}

.input input {
  font-size: 14px;
  height: 40px;
  width: 100%;
  border: 1px solid #ededed;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 14px 16px 14px 16px;
}

.input input[hideclear='false'] {
  padding-right: 35px;
}

.input input[readonly]:focus {
  outline: none;
  border: 1px solid #ededed;
}

.input input::placeholder {
  opacity: 0.6;
}

.input input[marker='error'] {
  border: 1px solid #f63f45;
}

.inputError {
  /* position: absolute;
  left: 0;
  bottom: 0; */
  margin-top: 4px;
  font-size: 12px;
  line-height: 18px;
  color: #f63f45;
}

.inputClear {
  position: absolute;
  top: 50%;
  right: 10px;
  height: 20px;
  width: 20px;
  transform: translateY(-50%);
}

.inputTime {
  position: absolute;
  right: 20px;
  height: 15px;
  width: 15px;
}

.select {
  position: relative;
  display: flex;
  flex-direction: column;
}

.selectError {
  margin-top: 4px;
  font-size: 12px;
  font-weight: bold;
  line-height: 12px;
  color: #f63f45;
}

.selectResult {
  font-size: 14px;
  height: 46px;
  line-height: 46px;
  width: 100%;
  border: 1px solid #ededed;
  box-sizing: border-box;
  border-radius: 6px;
  background: #fff;
  padding: 0 10px 0 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.selectResult > :nth-child(1) {
  flex: 1;
  line-height: 1.2;
}

.selectResult > .img {
  width: 8px;
  height: 14px;
  margin-left: 10px;
}

.selectResult[marker='placeholder'] > div:nth-child(1) {
  opacity: 0.3;
}

.selectResult[marker='placeholder']:hover > div:nth-child(1) {
  opacity: 1;
}

.options {
  position: absolute;
  top: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  border: 1px solid #ededed;
  box-sizing: border-box;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.12);
  z-index: 10;
  max-height: 360px;
  overflow-y: auto;
}

.option {
  width: 100%;
  padding: 0 10px 0 16px;
  font-size: 14px;
  height: 50px;
  line-height: 50px;
  box-sizing: border-box;
  border-bottom: 1px solid #efefef;
  white-space: pre;
  cursor: pointer;
  display: flex;
  flex: none;
  align-items: center;
}

.optionText {
  overflow-x: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.option[marker='selected'] {
  background: #efeffe !important;
}

.option:hover {
  background: #fcfcfc;
}

.customSelect {
  display: flex;
  align-items: center;
}
.coinSelectImg {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.radio > div {
  height: 18px;
  width: 18px;
  background: #fff;
  box-shadow: 0px 4px 4px rgba(10, 31, 68, 0.16);
  border-radius: 50%;
  transition: transform 0.2s linear;
}

.radio {
  height: 22px;
  width: 44px;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px;
}

.radio[marker='normal'] {
  background: #c9ced6;
}

.radio[marker='highlight'] {
  background: #3e3ef4;
}

.radio[marker='normal'] > div {
  transform: translateX(0px);
}

.radio[marker='highlight'] > div {
  transform: translateX(22px);
}

.img {
  display: flex;
  justify-content: center;
  user-select: none;
}

.imgContent {
  display: block;
  max-height: 100%;
  max-width: 100%;
}

.img[marker='buffer'] {
  display: none;
}

.img:hover .tooltip {
  display: flex;
}

.checkbox {
  composes: img;
}

.checkbox[marker='disabled'] {
  background: rgba(24, 28, 39, 0.04);
  cursor: default;
}

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 100%;
  width: 100%;
}

.pagingContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 60px;
  margin-bottom: 20px;
  align-items: center;
}

.pagingTitle,
.pagingLabel,
.pagingButton {
  box-sizing: border-box;
  overflow: hidden;
  user-select: none;
  display: block;
  font-weight: 500;
}

.pagingTitle {
  height: 18px;
  line-height: 18px;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #202124;
}

.pagingInputLength,
.pagingInputIndex {
  background: #fbfbfd;
  border: 1px solid #eaedf3;
  box-sizing: border-box;
  border-radius: 3px;
  font-size: 12px;
  margin-left: 14px;
  height: 24px;
  text-align: center;
}

.pagingInputLength {
  width: 45px;
}

.pagingInputIndex {
  width: 35px;
}

.pagingButton {
  width: 65px;
  height: 24px;
  line-height: 24px;
  background: #fbfbfd;
  border: 1px solid #eaedf3;
  box-sizing: border-box;
  border-radius: 3px;
  margin-left: 16px;
  cursor: pointer;
  font-size: 12px;
  text-align: center;
  color: #3e3ef4;
}

.pagingButton[marker='disable'] {
  color: #202124;
  opacity: 0.5;
}

.pagingLabel {
  height: 18px;
  line-height: 18px;
  margin-left: 5px;
  font-size: 14px;
}

.search {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.searchInput {
  width: 100%;
  height: 100%;
}

.searchInput input {
  width: 100%;
  line-height: 100%;
  background: #fff;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.05);
  border: none;
  border-radius: 8px;
  font-size: 14px;
  padding-left: 15px;
  padding-right: 90px;
  box-sizing: border-box;
}

.searchClearButton {
  position: absolute;
  height: 20px;
  width: 20px;
  right: 59px;
}

.searchButton {
  position: absolute;
  height: 20px;
  width: 20px;
  right: 15px;
}

.tabs {
  height: 48px;
  width: 100%;
  border-bottom: 1px solid #ededed;
  display: flex;
  flex-direction: row;
}

.tab {
  box-sizing: border-box;
  overflow: hidden;
  height: 100%;
  margin-right: 46px;
  line-height: 24px;
  cursor: pointer;
  font-size: 18px;
  display: block;
}

.tab[marker='normal'] {
  opacity: 0.5;
}

.tab[marker='highlight'] {
  border-bottom: 4px solid #3e3ef4;
  font-weight: bold;
  opacity: 1;
}

.roundButton {
  composes: button;
  /* overflow: hidden; */
  padding: 0 30px;
  line-height: 50px;
  border-radius: 6px;
  text-align: center;
  background: #3e3ef4;
  color: #fff;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.12);
  font-size: 16px;
  font-weight: 500;
}

.roundButton[marker='warning'] {
  background: #fff;
  border: 1px solid #f63f45;
  color: #f63f45;
  box-shadow: none;
}

.roundButton[marker='hollow'] {
  background: #fff;
  border: 1px solid #3e3ef4;
  color: #3e3ef4;
  box-shadow: none;
}

.roundButton[marker='light'] {
  background: #fff;
  border: 1px solid #d4d3d3;
  color: #202124;
  box-shadow: none;
}

.roundButton[marker='disabled'] {
  opacity: 0.4;
  box-shadow: none;
  cursor: not-allowed;
}

.tableTitle {
  display: flex;
  flex-direction: row;
  padding: 12px 0;
}

.tableTitle > div {
  font-size: 12px;
  font-weight: 400;
  color: #8e8f91;
  line-height: 20px;
}

.tableLine {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 0;
  border-top: 1px solid #ededed;
}

.tableLine:hover {
  background: #fcfcfc;
}

.panel {
  background: #fff;
  box-shadow:
    0px 3px 4px rgba(10, 31, 68, 0.1),
    0px 0px 1px rgba(10, 31, 68, 0.08);
  border-radius: 6px;
}

.codes {
  /* width: 400px; */
  min-width: 400px;
  width: 100%;
  height: 46px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.code {
  width: 46px;
  height: 46px;
  font-size: 24px;
  text-align: center;
  /* background: #f6f6f6;
  border: 1px solid #ededed;
  border-radius: 6px; */
  box-sizing: border-box;

  background: #ffffff;
  border-radius: 4px;
  border: 1px solid rgba(24, 28, 39, 0.09);

  font-weight: bold;
  color: #3e3ef4;
}

.code:focus {
  border: 2px solid #3e3ef4 !important;
}

.loader {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.loader > img {
  width: 120px;
  height: 120px;
}

.range {
  position: relative;
  height: 16px;
  cursor: pointer;
}

.range > div {
  display: block;
  position: absolute;
  left: 0;
  width: 100%;
  top: 5px;
  height: 6px;
}

.range input {
  display: block;
  position: absolute;
  cursor: pointer;
  left: 0;
  width: 100%;
  top: 6px;
  height: 0px;
  appearance: none;
  border: none;
}

.range input:focus {
  border: none;
  outline: none;
}

.range input::-webkit-slider-thumb {
  appearance: none;
  height: 16px;
  width: 16px;
  border-radius: 8px;
  background: #fff;
  border: 1px solid #ebebfd;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.12);
}

.unitInputContainer {
  position: relative;
  height: fit-content;
}

.unitInput input {
  padding-right: 46px;
}

.unitInputUnit {
  position: absolute;
  right: 10px;
  top: 24px;
  transform: translate(0, -7px);
  line-height: 14px;
  width: fit-content;
  font-weight: 500;
  font-size: 14px;
  color: #b5b5b6;
}

.balance {
  display: flex;
  flex-direction: row;
  /* justify-content: flex-end; */
  /* line-height: 26px; */
  text-align: right;
}
.amountLabel {
  font-weight: bold;
  white-space: pre;
}
.balanceBTC {
  font-weight: 500;
  font-size: 16px;
  color: #202124;
}
.balanceUSD {
  font-size: 14px;
  color: #fa8c16;
}
.balance[marker='light'] .balanceUSD {
  /* color: #bcbcbd; */
  color: rgba(24, 28, 39, 0.25);
}

.coinSelectPanel {
  position: absolute;
  z-index: 10;
  top: 150%;
  left: 20px;
  width: 600px;
  overflow: hidden;
}

.coinSelectPanelSearch {
  height: 48px;
  border-bottom: 1px solid rgba(24, 28, 39, 0.04);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px;
}

.coinSelectPanelSearchInput {
  height: 100%;
  flex: 1;
  margin: 0 10px;
}

.coinSelectPanelSearchInput input {
  height: 100%;
  width: 100%;
  border: none !important;
}

.coinSelectPanelSearchInput input:focus {
  border: none !important;
}

.coinSelectPanelSearchImg {
  width: 16px;
  height: 16px;
}

.coinSelectPanelContent {
  max-height: 400px;
  height: 250px;
  overflow-y: auto;
}

.coinSelectPanelSelected {
  margin-top: 1px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(24, 28, 39, 0.04);
}

.coinSelectPanelSelected > div {
  width: 100%;
}

.coinSelectPanelSelected > div:nth-child(1) {
  margin-top: 1px;
  display: flex;
  flex-direction: row;
  height: 42px;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 20px;
}

.coinSelectPanelSelected > div:nth-child(1) > :nth-child(1) {
  font-weight: 500;
  font-size: 14px;
  color: #202124;
}

.coinSelectPanelClear {
  font-weight: 500;
  font-size: 14px;
  /*color: #BCBCBD*/
  color: #202124;
  cursor: pointer;
  user-select: none;
  height: 30px;
  line-height: 28px;
  border: 1px solid #d4d3d3;
  padding: 0 15px;
}

.coinSelectPanelSelectAll {
  border-bottom: 1px solid #ededed;
}

.coinSelectPanelSelectAll .coinSelectPanelOption[marker='normal'] {
  color: #202124;
}

.coinSelectPanelAll {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #ededed;
}

.coinSelectPanelAll > div:nth-child(1) {
  font-weight: 500;
  font-size: 14px;
  color: #202124;
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  height: 42px;
  align-items: center;
}

.coinSelectPanelOption {
  height: 42px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.coinSelectPanelOption:hover {
  background: #ebebff;
}

.coinSelectPanelOptionImg {
  width: 18px;
  height: 18px;
  margin-left: 19px;
}

.coinSelectPanelOptionLabel {
  font-size: 14px;
  height: 18px;
  line-height: 18px;
  margin-left: 10px;
}

.coinSelectPanelOption[marker='normal'] {
  color: #7d7d7e;
}

.coinSelectPanelOption[marker='highlight'] {
  color: #3e3ef4;
}

.coinSelectPanelButtons {
  display: flex;
  flex-direction: row;
  padding: 0 20px;
  border-top: 1px solid rgba(24, 28, 39, 0.04);
  justify-content: space-between;
}

.coinSelectPanelButtons > div {
  width: 90px;
  height: 35px;
  font-weight: 500;
  font-size: 14px;
  line-height: 32px;
  border-radius: 4px;
  margin: 12px 0;
  text-align: center;
}

.coinSelectPanelButtonCancel {
  color: #181c27;
  border: 1px solid rgba(24, 28, 39, 0.09);
}

.coinSelectPanelButtonApply {
  background: #3e3ef4;
  color: #fff;
  border: 1px solid #3e3ef4;
}

.leaver {
  height: 36px;
  width: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.leaverImg {
  width: 6.6px;
  height: 11.2px;
  margin-left: 11px;
}

.leaverLabel {
  color: #3e3ef4;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-left: 11.4px;
  margin-right: 10px;
}
