/* Montserrat Font */
@font-face {
  font-family: Montserrat;
  src: url('assets/font/Montserrat-Medium.ttf');
}

@font-face {
  font-family: 'Montserrat-Medium';
  src: url('assets/font/Montserrat-Medium.ttf');
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: url('assets/font/Montserrat-Bold.ttf');
}

@font-face {
  font-family: 'Montserrat-SemiBold';
  src: url('assets/font/Montserrat-SemiBold.ttf');
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: url('assets/font/Montserrat-Regular.ttf');
}

@font-face {
  font-family: 'Montserrat-Light';
  src: url('assets/font/Montserrat-Light.ttf');
}

/* Ubuntu Font */
@font-face {
  font-family: 'Ubuntu';
  src: url('assets/font/Ubuntu-Regular.ttf');
}

@font-face {
  font-family: 'Ubuntu-Bold';
  src: url('assets/font/Ubuntu-Bold.ttf');
}

@font-face {
  font-family: 'Ubuntu-Medium';
  src: url('assets/font/Ubuntu-Medium.ttf');
}

@font-face {
  font-family: 'Ubuntu-Regular';
  src: url('assets/font/Ubuntu-Regular.ttf');
}

@font-face {
  font-family: 'Ubuntu-Light';
  font-weight: 100;
  src: url('assets/font/Ubuntu-Light.ttf');
}

/* Microsoft YaHei */
@font-face {
  font-family: 'Microsoft YaHei';
  src: url('assets/font/Microsoft\ YaHei-Regular.ttf');
}

@font-face {
  font-family: 'Microsoft YaHei-Bold';
  src: url('assets/font/Microsoft\ YaHei-Bold.ttf');
}

@font-face {
  font-family: 'Microsoft YaHei-Regular';
  src: url('assets/font/Microsoft\ YaHei-Regular.ttf');
}

@font-face {
  font-family: 'Microsoft YaHei-Light';
  src: url('assets/font/Microsoft\ YaHei-Light.ttf');
}

/* Poppins */
@font-face {
  /* font-family: 'Poppins-SemiBold'; */
  font-family: 'Poppins';
  font-weight: 600;
  src: url('assets/font/Poppins-SemiBold.ttf');
}

@font-face {
  /* font-family: 'Poppins-Regular'; */
  font-family: 'Poppins';
  font-weight: 400;
  src: url('assets/font/Poppins-Regular.ttf');
}

@font-face {
  /* font-family: 'Poppins-Medium'; */
  font-family: 'Poppins';
  font-weight: 500;
  src: url('assets/font/Poppins-Medium.ttf');
}

:global html {
  width: 100%;
  height: 100%;
  font-family:
    Montserrat,
    -apple-system,
    system-ui,
    'Helvetica Neue',
    'PingFang SC',
    'Microsoft YaHei',
    'Source Han Sans SC',
    'Noto Sans CJK SC',
    'WenQuanYi Micro Hei',
    sans-serif !important;
  font-size: 14px;
}

:global body,
:global #root {
  margin: 0;
  width: 100%;
  height: 100%;
  font-family:
    Montserrat,
    -apple-system,
    system-ui,
    'Helvetica Neue',
    'PingFang SC',
    'Microsoft YaHei',
    'Source Han Sans SC',
    'Noto Sans CJK SC',
    'WenQuanYi Micro Hei',
    sans-serif !important;
  font-size: 14px !important;
  /* overflow: auto; */
  position: relative;
}

:global input:focus {
  outline: none !important;
  border: 1px solid #3e3ef4 !important;
}

:global ul {
  margin: 0;
  padding: 0;
}
