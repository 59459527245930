@import '../../common/colors-variable.scss';
@import '../../common/fonts.scss';

.container {
  display: flex;
  flex-direction: column;
  background: #edf2f9;
  min-height: 100%;
  // padding: 24px 24px 200px;
}

.header {
  background: #fff;
  padding: 16px 24px;

  @include fontSize-20;
  @include boldFont;
  color: $mirage;
}

.content {
  margin: 24px 24px 200px;
  background: #fff;
  box-shadow: 0px 6px 18px -8px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  overflow: hidden;
}

.searchAdvancePanel {
  display: flex;
  flex-direction: column;
  padding: 0 24px 20px 24px;
  background: #f9fafd;
  // border-top: 1px solid rgba(24, 28, 39, 0.09);
}

.search {
  flex: 1;
}

.searchAdvance {
  height: 48px;
  line-height: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #3e3ef4;
  font-size: 14px;
  font-weight: 500;
}

.searchAdvanceButton {
  height: 6px;
  width: 10px;
  margin-left: 10px;
}

.searchRightSide {
  display: flex;
  align-items: center;
}

.searchToExcel {
  margin: 0 0 0 26px;
}

.searchToExcelImg {
  // box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);
  // border-radius: 4px;
  // border: 1px solid #ededed;
  // margin-right: 10px;
  // height: 100%;
  width: 21px;
  height: 24px;
}

.tablePanel {
  margin-top: 30px;
  position: relative;
}

.tableCell {
  // width: 100%;
  overflow-wrap: break-word;
  // white-space: pre-wrap;
}

.tableCellDescription {
  color: rgba(24, 28, 39, 0.425);
  @include fontSize-12;
}

.tableCellStatus {
  background: #f9fafd;
  padding: 4px 8px;
  border-radius: 8px;
  @include fontSize-12;
  @include semiBoldFont;
}

.tableCellStatus[marker='success'] {
  color: #52c41a;
}

.tableCellStatus[marker='failed'] {
  color: #f5222d;
}

.tableCellStatus[marker='pending_confirmation'] {
  color: #1890ff;
}

.tableCellStatus[marker='pending_doublecheck'] {
  color: #fa8c16;
}

.tableCellStatus[marker='pending_approval'] {
  color: #fa8c16;
}

.tableCellStatus[marker='pending_cobo_approval'] {
  color: #fa8c16;
}

.tableCell[marker='send'] {
  color: #181c27;
}

.tableCell[marker='receive'] {
  color: #52c41a;
}

.tableCellTypeImg {
  composes: tableCell;
  width: 20px;
  height: 20px;
  cursor: default;
  flex-shrink: 0;
}

.tableCellGoImg {
  width: 18px;
  height: 18px;
}

.detailModal {
  width: 650px;
  padding-bottom: 50px;
}

.detailLine {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ededed;
  min-height: 62px;
  padding: 20px 0;
  margin: 0 20px;
  box-sizing: border-box;
}

.detailLine > :nth-child(1) {
  flex: 1;
  line-height: 22px;
  font-size: 14px;
  color: #a6a7a8;
}

.detailLine > :nth-child(2) {
  text-align: right;
  word-break: break-all;
  height: fit-content;
  flex: 4;
  line-height: 24px;
  font-size: 16px;
  color: #202124;
  font-weight: 500;
}

.detailLine[marker='highlight'] > :nth-child(2) {
  color: #3e3ef4;
}

.detailLine[marker='success'] > :nth-child(2) {
  color: #11bf5a;
}

.detailLine[marker='amount'] > :nth-child(2) {
  color: #ff6d26;
}

.detailLine[marker='pending_confirmation'] > :nth-child(2) {
  color: #2f75ec;
}

.detailLine[marker='pending_approval'] > :nth-child(2) {
  color: #f2752e;
}

.detailLine[marker='fail'] > :nth-child(2) {
  color: #f63f45;
}

.modalLabel {
  height: 18px;
  line-height: 18px;
  font-size: 14px;
  text-decoration: none;
}

.modalButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 62px;
  padding: 20px 0;
  margin: 0 20px;
  box-sizing: border-box;
}

.modalButton {
  font-size: 16px;
  width: 200px;
}
.modalButton + .modalButton {
  margin-left: 40px;
}

.flexRow {
  width: 100%;
  display: flex;
  align-items: center;
}

.searchContainer {
  display: flex;
  align-items: center;
  padding: 12px 24px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.searchWrapper {
  display: flex;
  align-items: center;
  margin: 0 24px 0 0;
}

.searchInputContainer {
  width: 500px;
  padding: 0;
  background: white;
  margin: 0 0 0 12px;
  padding: 0 12px;
}

.searchIcon {
  width: 14px;
  height: 14px;
}

.searchSelect {
  padding: 3px;
}

.searchButton {
  padding: 10px 25px !important;
  margin: 0 0 0 5px;
}

.tableContainer {
  border-top: 1px solid rgba(24, 28, 39, 0.09);
  border-radius: 0;
}

.tooltip {
  min-width: 90%;
}

.copyTableCell {
  width: 100%;
  cursor: pointer;
}

.walletCoinsGo {
  width: 18px;
  height: 18px;
  flex-shrink: 0;
}
