@import 'common/fonts.scss';
@import 'common/colors-variable.scss';

.container {
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  min-height: 100%;
}

.leftSide {
  min-height: 100%;
  width: 50%;
  background: url('../../../../assets/img/cobo_lite/onboarding_bg.png') center
    center no-repeat;
  background-size: cover;
}

.leftSideContent {
  margin: 25% auto;
  padding: 0 96px;
  max-width: 720px;
}

.title {
  @include fontSize-52;
  @include regularFont;
  color: #697aad;
  margin: 16px 0 0 0;
}

.description {
  @include fontSize-20;
  @include mediumFont;
  color: #697aad;
  margin: 16px 0 0 0;
}

.coboLogo {
  display: flex;
  justify-content: flex-start;
  height: 30px;
}

.rightSide {
  width: 50%;
  min-height: 100%;
  position: relative;
}

.rightSideHeader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: end;
  padding: 16px 24px;
}

.content {
  margin: 25% 0;
  max-width: 720px;
  padding: 0 100px;

  .loginTitle {
    width: 100%;
    font-size: 32px;
    font-weight: bold;
    color: #202124;
  }

  .label {
    width: 100%;
    height: 15px;
    line-height: 15px;
    color: #202124;
    text-align: left;
    font-size: 12px;
  }

  .button {
    height: 54px;
    margin: 32px 0 24px 0;
  }

  .tips {
    margin-top: 24px;
    color: #8590a6;
    @include fontSize-16;
    .link {
      cursor: pointer;
      font-weight: 700;
      color: $royal-blue;
    }
  }
}

@media (max-width: 1024px) {
  .leftSide {
    display: none;
  }

  .rightSide {
    width: 100%;
  }

  .content {
  }
}

.envLanguageWrapper {
  display: flex;
  align-items: center;
}

.envBox {
  display: flex;
  align-items: center;
  background: #f9fafd;
  border-radius: 6px;
  border: 1px solid rgba(24, 28, 39, 0.08);

  margin: 0 16px 0 0;
  padding: 6px 8px;

  @include fontSize-14;
  @include mediumFont;
  color: #181c27;
}

.envIcon {
  width: 20px;
  height: 20px;
  margin: 0 8px 0 0;
  cursor: default;
}
